.mbx-admin_offer_status {
  @apply flex;
  position: absolute;
  top: 24px;
  right: 64px;

  &__text {
    margin-top: -1px;
    font-size: 10px;
    line-height: 15px;
    text-align: right;
  }

  &__labels {
    @apply ml-8;
  }

  &__label {
    @apply flex items-center justify-center text-gray-700 bg-white;
    width: 112px;
    padding: 3px 0;
    border: solid 1px;
    font-size: 9px;
    line-height: 10px;
    @apply border-gray-400;

    &:not(:first-child) {
      @apply mt-4;
    }
  }
}
