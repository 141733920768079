// 下層要素に汎用のhover演出をかけたい時用
.mbx-utils {
  &-hover {
    &:hover {
      .hover_opacity {
        @apply transition-opacity;
        opacity: 0.3;
      }
      .hover_gray-500 {
        @apply transition-colors;
        @apply bg-gray-500;
      }
    }
  }
}
