.top,
.interview,
.event {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    &-inner {
      nav {
        flex-direction: column;

        ul {
          li {
            display: flex;
            align-items: center;

            &:not(:first-child) {
              margin-left: 2.539vw;
            }
          }
        }
      }
    }

    &-btn {
      width: 19.141vw;
    }
  }

  .interview {
    width: 95.703vw;
    margin: 1.4648vw auto 0;
    padding-top: 9.57vw;
    padding-bottom: 15.625vw;

    &-title {
      font-size: 2.539vw;
      font-weight: bold;
      line-height: 5.859vw;
      text-align: center;
    }

    &-subtitle {
      @apply font-fira;
      font-size: 1.27vw;
      font-weight: 300;
      letter-spacing: 0.1em;
      text-align: center;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 27.344vw);
      justify-content: space-between;
      margin-top: 4.395vw;

      a {
        transition-duration: .15s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .profile {
      margin-top: 1.367vw;
      padding-bottom: 0.781vw;
      border-bottom: 1px solid #cccccc;
    }

    .num,
    .name {
      font-size: 1.465vw;
    }

    .job {
      margin-left: 2.148vw;
      font-size: 1.172vw;
    }

    .name {
      margin-left: 1.953vw;
    }

    .question {
      margin-top: 1.563vw;
      font-size: 1.367vw;
      font-weight: bold;
      letter-spacing: -0.02vw;
      line-height: 2.93vw;
    }

    .answer {
      display: -webkit-box;
      max-height: 4.297vw;
      margin-top: 0.781vw;
      overflow: hidden;
      font-size: 1.27vw;
      letter-spacing: 0.049vw;
      line-height: 2.148vw;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .top-footer {
    padding-top: 9.18vw;
    background-color: #f7f7f7;

    .text {
      display: block;
      font-size: 1.172vw;
      font-weight: bold;
      text-align: center;
    }

    img {
      width: 17.578vw;
      margin: 18px auto 0;
    }

    .contact {
      display: flex;
      justify-content: center;
      margin-top: 4.102vw;

      .link {
        margin: 0 1.465vw;

        span {
          color: #333333;
          font-size: 1.367vw;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }

    &_bottom {
      margin-top: 8.789vw;
      background-color: #333333;
      color: #ffffff;

      .bottom-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95.703vw;
        height: 9.473vw;
        margin: 0 auto;

        .copyright {
          font-size: 1.27vw;
          letter-spacing: 0.0075em;
        }

        .links {
          display: flex;

          .link {
            span {
              @apply text-white;
              font-size: 1.367vw;
              font-weight: unset;
              letter-spacing: 0.0075em;
            }

            &:not(:first-child) {
              margin-left: 1.953vw;
            }
          }
        }
      }
    }
  }
}

.top {
  overflow: hidden;
  color: #333333;
  line-height: 1;

  .main-bnr {
    margin-top: 80px;
    font-weight: bold;
    position: relative;

    .main-slide {
      position: relative;
      width: 100%;
      height: 63.539vw;
      overflow: hidden;

      h3 {
        position: absolute;
        z-index: 1;
        top: 5.078vw;
        left: 50%;
        transform: translateX(-50%);
        font-size: 2.734vw;
        white-space: nowrap;

        br {
          display: none;
        }
      }

      img {
        position: absolute;
        max-width: unset;
        width: unset;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .slick-dots {
      bottom: -4.687vw;

      .slick-active {
        button {
          &::before {
            color: #ff5b38;
          }
        }
      }
    }

    .mynaviworks-logo {
      position: absolute;
      bottom: 30px;
      display: flex;
      justify-content: end;
      width: 100%;

      img {
        width: auto;
      }
    }
  }

  .top-list {
    display: grid;
    grid-template-columns: repeat(3, 27.344vw);
    justify-content: space-between;
    width: 95.703vw;
    margin: 4.395vw auto 0;
    padding-top: 9.57vw;
    padding-bottom: 15.625vw;

    .list {
      transition: filter 0.2s;
    }

    .list:hover {
      filter: opacity(70%);
    }

    .list-title {
      margin-top: 2.734vw;
      font-size: 1.953vw;
      font-weight: bold;
      letter-spacing: -.02vw;
    }

    .list-text {
      margin-top: 1.953vw;
      font-size: 1.3671vw;
      font-weight: 300;
      letter-spacing: -.02vw;
      line-height: 2.3436vw;

      & br {
        display: none;
      }
    }
  }

  .event {
    width: 95.703vw;
    margin: 4.1016vw auto 0;
    padding-top: 9.5703vw;

    .event-bg {
      padding: 3.2227vw 5.8593vw 0;
      width: 100%;
      height: 24.9023vw;

      .event-title {
        font-size: 2.539vw;
        font-weight: 700;
        line-height: 5.859vw;
      }

      .event-subtitle {
        font-family: "Fira Sans", sans-serif;
        font-size: 1.27vw;
        font-weight: 300;
        letter-spacing: .1em;
      }

      .event-text {
        margin-top: 1.9531vw;
        font-size: 1.563vw;
        letter-spacing: .05em;
        line-height: 4.492vw;
      }

      .event-link {
        margin-top: 0;
        font-size: 1.367vw;
        font-weight: 700;
        letter-spacing: -.02vw;
        line-height: 2.93vw;
        text-align: right;
      }

      .mbx-icon {
        position: relative;
        top: 2px;
        display: inline-block;
        padding-right: 2px;

        &::before {
          vertical-align: baseline !important;
        }
      }
    }
  }

  .introduction {
    padding-bottom: 16.406vw;
    background-size: cover;

    &-top {
      display: flex;
      position: relative;
      justify-content: space-between;
      width: 95.703vw;
      margin: 0 auto;
      padding: 0 2.93vw;
      padding-left: 3.613vw;

      .wrap {
        padding-top: 14.648vw;
        padding-bottom: 6.445vw;
      }

      .introduction-images {
        position: absolute;
        right: 2.93vw;
      }
    }

    &-bottom {
      display: flex;
      position: relative;
      left: 0;
      justify-content: flex-end;
      width: 95.703vw;
      margin: 160px auto 0;

      .wrap {
        padding-top: 2.734vw;
        padding-bottom: 5.273vw;
      }

      .introduction-images {
        position: absolute;
        left: 0px;
      }
    }

    &-title {
      font-size: 2.441vw;
      font-weight: bold;
      letter-spacing: 0.05em;
      line-height: 5.664vw;
    }

    &-text {
      margin-top: 5.078vw;
      font-size: 1.563vw;
      letter-spacing: 0.05em;
      line-height: 4.492vw;
    }

    &-btn {
      width: 12.012vw;
      margin-top: 6.641vw;
      border: 1px solid #a1a1a1;
      border-radius: 0.391vw;
      background-color: #f3f5ee;
      color: #636665;
      font-size: 1.367vw;
      font-weight: bold;
      line-height: 4.297vw;
      text-align: center;
      cursor: pointer;

      &:hover {
        @apply bg-white;
      }
    }

    .lead-img01 {
      width: 42.188vw;
      transform: translateY(-2.93vw);
    }

    .lead-img02 {
      width: 42.188vw;
      height: 25.781vw;
      object-fit: 'none';
      transform: translate(-10.645vw, -14.453vw);
    }

    .lead-img03 {
      width: 57.617vw;
      transform: translateX(-5.566vw);
    }

    .lead-img04 {
      width: 35.938vw;
      object-fit: 'none';
      transform: translate(20.531vw, -6.273vw);
    }

    //仮入れリンク
    .logo-link {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-top: 8.7vw;

      >p {
        &:nth-child(1) {
          font-size: 1.564vw;
          line-height: 2.346vw;
          padding-right: 4.106vw;
          border-right: 1px solid #a1a1a1;
        }

        &:nth-child(2) {
          font-size: 1.466vw;
          padding-left: 3.519vw;
          color: #0857a8;

          i {
            font-size: 2.346vw;
            margin-left: 0.978vw;
          }

          a {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .logo-list {
      @apply bg-white;
      /*display: flex;
      align-items: center;*/
      width: 95.703vw;
      /*height: 9.766vw;*/
      margin: 13.883vw auto 0;
      border-radius: 0.391vw;

      padding: 2.932vw 1.466vw 1.466vw;
      text-align: center;

      .text {
        /*padding-right: 5.859vw;
        padding-left: 4.688vw;*/
        border-bottom: 1px solid #a1a1a1;
        font-weight: bold;
        line-height: 2.637vw;

        display: inline-block;
        padding-bottom: 0.489vw;
        text-align: center;
      }

      .list {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-wrap: wrap;
        }

        li {
          display: inline-flex;
          justify-content: center;
          width: 146px;

          /*&:not(:first-child) {
            margin-left: 0.293vw;
          }*/

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          @media screen and (max-width: 789.99px) {
            width: 33.33%;

            img {
              width: 146px;
              height: auto;
            }
          }
        }
      }
    }
  }

  .start {
    padding: 9.18vw 0;
    text-align: center;

    p {
      font-size: 1.465vw;
      letter-spacing: 0.059vw;
    }

    .base-button {
      width: 26.367vw;
      height: 5.469vw;
      margin-top: 3.91vw;

      .label {
        font-size: 1.66vw;
      }
    }
  }

  .recruit-voice {
    position: relative;
    background-color: #e2ebea;
    background-repeat: no-repeat;
    background-size: 100% auto;

    &_inner {
      display: flex;
      position: relative;
      width: 95.703vw;
      margin: 0 auto;
      padding-top: 19.238vw;
      padding-bottom: 14.648vw;

      .wrap {
        padding-left: 10.547vw;
      }
    }

    &_title {
      font-size: 2.734vw;
      font-weight: bold;
      letter-spacing: 0.098vw;
    }

    &_subtitle {
      @apply font-fira;
      margin-top: 2.051vw;
      font-size: 1.367vw;
      font-weight: 300;
      letter-spacing: 0.1em;
    }

    &_description {
      margin-top: 5.859vw;
      font-size: 1.563vw;
      letter-spacing: 0.03em;
      line-height: 4.492vw;
    }

    .slide-area {
      position: relative;
    }

    .slide-control {
      position: absolute;
      right: 3.125vw;
      bottom: 16.667vw;

      .disabled {
        opacity: 0.3;
      }

      img {
        width: 1.172vw;
        height: 2.344vw;
        transform: rotate(90deg);

        &:first-child {
          margin-bottom: 1.953vw;
        }
      }
    }

    &_slider {
      width: 37.695vw;
      height: 100%;
      margin-left: 7.984vw;

      .slick-arrow {
        display: none !important;
      }

      .slick-slide {
        height: 17.285vw;
        transition-duration: .15s;

        &:hover {
          opacity: 0.7;
        }
      }

      .slide-item {
        .item {
          display: flex;
        }

        .slide-text {
          margin-left: 2.734vw;

          .name {
            font-size: 1.563vw;
            font-weight: bold;
            letter-spacing: 0.1em;
            line-height: 2.346vw;
          }

          .text {
            font-size: 1.465vw;
            letter-spacing: 0.005em;
            line-height: 2.637vw;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }

        img {
          display: inline-block;
          width: 128px !important;
          height: 12.5vw;
        }
      }
    }
  }

  .everyone-portfolio {
    padding-top: 17.09vw;
    padding-bottom: 17.578vw;

    &_title {
      font-size: 2.734vw;
      font-weight: bold;
      text-align: center;
    }

    &_subtitle {
      @apply font-fira;
      margin-top: 2.344vw;
      font-size: 1.367vw;
      font-weight: 300;
      letter-spacing: 0.095em;
      text-align: center;
    }

    .portfolio-slider {
      &_control {
        display: flex;
        justify-content: space-between;
        margin-top: 0.977vw;
        padding: 0 11.719vw;

        img {
          width: 1.172vw;
          height: 2.344vw;
        }
      }
    }

    .slide-items {
      margin-top: 4.883vw;

      .slick-list {
        overflow: visible !important;
      }

      .slide-item {
        position: relative;
        z-index: 2;
        transform-origin: 50% 50%;
        transition: transform 0.3s cubic-bezier(0.5, 1, 0.89, 1);

        .bnr-area {
          margin: 0 2.246vw;
          padding: 1.563vw;
          box-shadow: 0px 0px 8px 0px rgba(111, 119, 116, 0.2);

          img {
            width: 23.242vw;
            border: 1px solid #f2f2f2;
          }
        }

        .title {
          margin-left: 2.246vw;
          padding-top: 2.344vw;
          font-size: 1.66vw;
          letter-spacing: 0.01em;
        }

        .user-info {
          display: flex;
          align-items: center;
          margin-top: 2.93vw;
          margin-left: 2.246vw;

          img {
            width: 6.641vw;
            height: 6.641vw;
            border-radius: 50%;
          }

          .name {
            margin-left: 0.977vw;
            font-size: 1.367vw;
            font-weight: bold;
          }

          .job {
            margin-top: 1.465vw;
            margin-left: 0.977vw;
            font-size: 1.172vw;
          }
        }

        &:hover {
          transform: scale(1.2);
          transform-origin: 50% 50%;
          transition: transform 0.3s cubic-bezier(0.5, 1, 0.89, 1);
          color: #808080;
        }
      }
    }
  }

  .questions {
    padding-top: 17.285vw;
    padding-bottom: 17.188vw;

    &-inner {
      width: 95.703vw;
      margin: 0 auto;
    }

    &-title {
      font-size: 2.734vw;
      font-weight: bold;
      text-align: center;
    }

    &-subtitle {
      @apply font-fira;
      margin-top: 1.855vw;
      font-size: 1.367vw;
      font-weight: 300;
      text-align: center;
    }

    &-contents {
      display: flex;
      justify-content: space-between;
      margin-top: 6.836vw;

      .content {
        width: 25.391vw;
        border-bottom: 1px solid #a1a1a1;

        .q-num {
          padding-bottom: 0.977vw;
          border-bottom: 1px solid #a1a1a1;
          font-size: 1.758vw;
          text-align: center;
        }

        .question {
          margin-top: 2.148vw;
          font-size: 1.563vw;
          font-weight: bold;
          letter-spacing: 0.02em;
          line-height: 2.734vw;
        }

        .answer {
          margin-top: 1.563vw;
          padding-bottom: 2.344vw;
          font-size: 1.465vw;
          letter-spacing: 0.03em;
          line-height: 2.93vw;
        }
      }
    }

    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 7.617vw;

      i {
        @apply text-button-iconButton-red;
        font-size: 2.246vw;
      }

      p {
        margin-left: 1.563vw;
        font-size: 1.66vw;
        font-weight: bold;
        letter-spacing: 0.01em;
      }
    }
  }
}
