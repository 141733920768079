.mbx-works-preview-image {
  &_style-v-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 48px;
  }
  &_style-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 48px;
  }

  &_style-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 48px;
  }

  &_style-8 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 48px;
  }

  .work {
    @media print {
      .embedThumbnail {
        display: block;
      }
      .embed {
        display: none;
      }
    }
    @media screen {
      .embedThumbnail {
        display: none;
      }
      .embed {
        display: block;
      }
    }

    .thumbnail {
      cursor: pointer;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @apply bg-gray-50;
        transform: scale(1);
        transition: transform 0.4s cubic-bezier(0.5, 1, 0.89, 1);
      }
      &:hover > img {
        transform: scale(1.2);
      }
    }
  }
}

.field-child {
  background: none;
}

.field-content {
  &-style-v-2 {
    width: 928px;
    height: 618px;
  }

  &-style-2 {
    width: 375px;
    height: 1125px;
  }

  &-style-4 {
    width: 520px;
    height: 728px;
  }

  &-style-8 {
    width: 928px;
    height: 556px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .mbx-works-preview-image {
    &_style-v-2 {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 16px;
    }
    &_style-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      row-gap: 16px;
    }

    &_style-4 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      row-gap: 16px;
    }

    &_style-8 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      row-gap: 16px;
    }

    .work {
      .text {
        margin-top: 8px;
      }
    }
  }
}
