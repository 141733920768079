.mbx-button-iconButton {
  @apply outline-none appearance-none focus:outline-none  rounded-full flex justify-center items-center text-24_24 transition-colors;
  // アドホックな指定
  // https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/973
  &.type-clear {
    @apply text-button-iconButton-clear hover:text-button-iconButton-clear-hover;
  }
  &.hitArea_default {
    @apply w-48 h-48;
  }
  &.hitArea_mini {
    @apply w-24 h-24;
  }
  &.hitArea_medium {
    @apply w-28 h-28;
  }
  .mbx-icon {
    @apply block;
  }
  &.isDisabled {
    @apply cursor-not-allowed text-gray-400;
  }

  // tailwindのカラー指定がJSの条件でのみ生成されcss内で利用されてないとプロダクト生成時に生成されないので便宜的に指定
  &.color-gray {
    @apply text-button-iconButton-gray;
  }
  &.color-red {
    @apply text-button-iconButton-red;
  }
  &.color-blue {
    @apply text-button-iconButton-blue;
  }
  &.color-attention {
    @apply text-button-iconButton-attention ;
  }
  &.color-delete {
    @apply text-button-iconButton-delete ;
  }
  &.color-white {
    @apply text-button-iconButton-white ;
  }

  &.size_large {
    width: 40px !important;
    height: 40px !important;
    font-size: 40px;
  }
  &-shadow {
    @apply outline-none appearance-none focus:outline-none text-white cursor-pointer w-48 h-48 flex items-center justify-center relative;
    i {
      @apply z-10 absolute transition-all;
    }
    .bg {
      @apply bg-gray-400 w-22 h-22 z-0 absolute;
    }
    &:hover {
      @apply text-gray-500;
      i {
        @apply drop-shadow-none;
      }
      .bg {
        @apply bg-gray-700;
      }
    }
  }
}
