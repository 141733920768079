.mbx-motivation-gauge {
  display: inline-block;
  &.sp {
    ul {
      @apply mb-6;
      li {
        width: 36px;
        height: 36px;
      }
    }
  }
  & > ul {
    display: flex;
    justify-content: space-between;
    margin: 0 -2px;
    margin-bottom: 6px;
    li {
      width: 24px;
      height: 24px;
      margin: 0 2px;
      img {
        filter: grayscale(100%);
        display: block;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        border-color: transparent;
      }
      &.active {
        img {
          filter: none;
          @apply border-corporate-red;
        }
      }
    }
  }
  & > section {
    display: flex;
    align-items: center;
    & > h3 {
      @apply text-gray-700 text-10_16;
    }
    & > p {
      @apply text-black text-10_16 font-bold;
      flex: 1;
      text-align: left;
      &.value-5 {
        @apply text-gray-500;
      }
    }
  }
}
