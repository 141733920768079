.mbx-matchdegree {
  &--small {
    @media (max-width: 767px) {
      width: 40px;
      margin-left: auto;
      margin-right: auto;
      transform: scale(.9);
    }
  }
  &--image {
    position: relative;
  }
  &--image-l,
  &--image-s {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &--image-l{
    width: 56px;
    height: 56px;
    clip-path: circle(27px at 50% 50%);
  }
  &--image-s{
    width: 26px;
    height: 26px;
    clip-path: circle(13px at 50% 50%);
  }
  &--text-l,
  &--text-m,
  &--text-s {
    @apply font-bold
  }
  &--text-l {
    margin-top: 8px;
    @apply text-body_2
  }
  &--text-m {
    @apply text-10_18
  }
  &--text-s {
    margin-left: 8px;
    margin-top: 2px;
    @apply text-12_18;
    @media (max-width: 767px) {
      .mbx-matchdegree--small & {
        margin-left: 5px;
        @apply text-10_10
      }
    }
  }
  &--progress-l,
  &--progress-m,
  &--progress-s {
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 3px;
    vertical-align: middle;
    @apply font-number
  }
  &--progress-l {
    @apply text-18_20
  }
  &--progress-m {
    @apply text-14_18
  }
  &--progress-s {
    margin-top: 1px;
    @apply text-18_18;
    @media (max-width: 767px) {
      .mbx-matchdegree--small & {
        margin-bottom: 0;
        margin-left: 0;
        @apply text-12_12
      }
    }
  }
}
