.fade-in {
  &_right {
    opacity: 0;
    transform: translateY(20px) translateX(20px);
  }
  &_left {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }
  &_in {
    opacity: 1;
    transform: translateY(0px) translateX(0px);
    transition: opacity 0.8s ease, transform 1.2s ease-out;
  }
}
.scroll-in {
  opacity: 1;
  transform: translateY(0px) translateX(0px);
  transition: opacity 0.8s ease, transform 1.2s ease-out;
}
.logo {
  &-in {
    opacity: 1;
    transition: opacity 0.7s ease;
  }
  &-out {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
}

.finger-move {
  animation-name: fingerMove;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-delay: 0.5s;
  animation-direction: normal;
}

@keyframes fingerMove {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-20px);
  }
  75% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
