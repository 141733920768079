.mbx-profileImageSquare {
  @apply relative text-white cursor-pointer;
  img {
    @apply block bg-gray-400 transition-opacity;
  }
  .icon {
    @apply w-40 h-40 rounded-full bg-gray-700 flex justify-center items-center text-icon -right-8 -bottom-8 absolute transition-colors;
  }
  &.mbx-profileImageSquare--preview {
    @apply cursor-default;
  }
  &:not(.mbx-profileImageSquare--preview):hover {
    img {
      opacity: 0.3;
    }
    .icon {
      @apply bg-gray-500;
    }
  }
}
