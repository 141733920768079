.mbx-send_shared_companies_modal {
  @apply flex   items-center justify-center;
  width: 784px;
  padding: 80px;
  border-radius: 4px;

  &__inner {
    @apply flex flex-col items-center justify-center;
    width: 624px;
  }

  .conditions {
    @apply w-full p-40 bg-gray-50 border-2 rounded-4 border-gray-600 text-left;

    &__inner {
      @apply overflow-scroll max-h-200;
    }

    &__list {
      @apply mt-22;
    }

    &__list-item {
      @apply text-12_20;

      &:not(:first-child) {
        @apply mt-12;
      }
    }
  }

  &__title {
    @apply mt-46 font-bold text-12_16;
    align-self: flex-start;
  }

  &__input {
    @apply mt-14 w-full;
    width: 100%;
  }

  &__ui {
    @apply mt-48;
  }

  &__button {
    width: 308px;
  }
}
