.details-preview {
  &-header {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
  &-wrapper {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .others {
    margin-bottom: 80px;
    border-top: 1px solid #000000;
    &-field {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 48px;
    }
  }

  .list {
    .title {
      font-weight: bold;
    }
  }
  .details-right {
    .title {
      font-weight: bold;
    }
  }
  @media screen {
    //embedがある場合、img-mainは表示しない
    .embed + .img-main {
      display: none;
    }
    .embed {
      display: block;
    }
  }

  @media print {
    .img-main {
      display: block;
    }
    .embed {
      display: none;
    }
  }
}
.sp_only {
  display: none;
}
@media screen and (max-width: 1023px) {
  .details-preview {
    .pc_only {
      display: none;
    }
    .img-main {
      width: 100vw;
      margin: 0 calc(50% - 50vw);
    }

    .md\:flex-col-reverse {
      flex-direction: column;
    }

    .main {
      order: 1;
      border-bottom: 1px solid#E0E0E0;
    }

    .menu {
      order: 2;
    }

    .others {
      &-field {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .field-content {
    width: 100vw;
  }
}

@media screen and (max-width: 826px) {
  .details-preview-wrapper{
    justify-content: flex-end !important;
  }
  .sp_only {
    display: block;
  }
  .sp_none {
    display: none !important;
  }
  .sp-edit_btn {
    .base-button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .details-preview {
    .pc_only {
      display: none;
    }

    .title-first {
      margin-top: 32px;
    }

    .images-area {
      width: 100%;
      margin-top: 32px;

      .work {
        margin-top: 16px;
      }

      .back {
        line-height: 64px;
      }
    }

    .others {
      margin-bottom: 48px;

      .title {
        font-size: 14px;
        line-height: 20px;
      }

      &-field {
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
      }
    }
  }

  .works-field {
    &_style-2 {
      column-gap: 16px;
    }

    &_style-4 {
      column-gap: 16px;
    }

    &_style-8 {
      column-gap: 16px;
    }
  }

  .field-content {
    &-style-v-2 {
      width: 100%;
      height: 66.667vw;
    }

    &-style-2 {
      width: 100%;
      height: 299.467vw;
    }

    &-style-4 {
      width: 100%;
      height: 139.467vw;
    }

    &-style-8 {
      width: 100%;
      height: 59.733vw;
    }
  }
}
