@import '../../mixins/mq';

.mbx-cropper {
  .cropper-wrap-box,
  .cropper-drag-box,
  .cropper-container {
    border-radius: 4px;
  }
  &_control {
    width: 300px;
    margin: auto;
    input[type='range'] {
      -webkit-appearance: none;
      appearance: none;
      background: rgba(0, 0, 0, 0.5);
      height: 3px;
      border-radius: 3px;
      width: 100%;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        position: relative;
        border: none;
        width: 16px;
        height: 16px;
        display: block;
        background: #ddd;

        border-radius: 50%;
        -webkit-border-radius: 50%;
      }
    }
  }
  &_button-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 30px auto;
    & > .base-button {
      @include mq('sm') {
        width: 140px;
      }
    }
  }
  &_img-align {
    margin: 30px auto;
    width: 240px;
    background: #eee;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
      button {
        margin: 0;
        padding: 5px 10px;
        flex: 1;
        height: 30px;
        border-left: 1px solid #ccc;
        text-align: center;
        &:first-child {
          border: none;
        }
        img {
          width: auto;
          height: 15px;
          margin: 0 auto;
        }
      }
  }
}
