.mbx-profileImage {
  width: 128px;
  height: 128px;
  @apply relative text-white cursor-pointer;
  @include mq('sm') {
    width: 80px;
    height: 80px;
  }
  img {
    @apply block bg-gray-400 transition-opacity;
  }
  label {
    @apply cursor-pointer;
  }
  .icon {
    @apply w-40 h-40 rounded-full bg-gray-700 flex justify-center items-center text-icon z-10 right-0 bottom-0 absolute transition-colors;
    @include mq('sm') {
      @apply -right-8 -bottom-8;
    }
  }
  &:hover {
    img {
      opacity: 0.3;
    }
    .icon {
      @apply bg-gray-500;
    }
  }
}
#mbx-profileImage_file {
  display: none;
}
