.direct {
    position: relative;
    font-size: 1rem;
    line-height: 2;
    letter-spacing: 0.025em;

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        line-height: 1;
        border-radius: 0.25rem;
        box-sizing: border-box;
        transition-duration: .15s;
    }

    .bt_fill {
        color: white;
        background-color: #0E58A6;

        &:hover {
            background-color: #1E80E3;
        }

        &:focus {
            background-color: #003B78;
        }
    }

    .bt_frame {
        color: #0E58A6;
        background-color: #FFF;
        border: solid 2px #0E58A6;

        &:hover {
            background-color: #DEEFFF;
        }

        &:focus {
            color: #FFF;
            background-color: #003B78;
            border-color: #003B78;
        }
    }

    header {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;

        .button {
            width: 10.75rem;
            height: 2.5rem;
            font-size: 0.875rem;
        }
    }

    .mainvisual {
        height: 31rem;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 15rem;
            background: url(/images/Direct/direct_mv-bg.jpg) no-repeat;
            background-size: auto 100%;
            background-position: 63% 0;
            z-index: 1;
        }

        .mv-contents {
            position: relative;
            z-index: 2;
        }

        h1 {
            font-size: 1.5rem;
            line-height: 2.25rem;
            font-weight: bold;
        }

        em {
            color: #0E58A6;
            font-style: normal;
        }

        p {
            font-weight: bold;
        }

        .button {
            width: 100%;
            height: 3.5rem;
            font-size: 1.0625rem;
        }
    }

    h2 {
        color: #0E58A6;
        font-size: 1.25rem;
        line-height: 2.125rem;
        font-weight: 700;
        text-align: center;
    }

    h3 {
        font-size: 1.025rem;
        font-weight: 700;
        text-align: center;
    }

    .inqu {
        background-color: #EEF3F9;

        h3 {
            font-size: 1.125rem;
        }

        .button {
            width: 100%;
            height: 3.5rem;
            font-size: 1.0625rem;
        }
    }


    dt {
        font-weight: 700;
    }

    .solution {

        dt {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 5.9375rem;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            background: #ffffff;
            border: 0.1875rem solid #E4ECF5;
            border-radius: 2.96875rem;
        }

        dt:before {
            content: "";
            position: absolute;
            bottom: -1.5rem;
            left: 50%;
            margin-left: -1.1875rem;
            border-style: solid;
            border-right: 1rem solid transparent;
            border-left: 1rem solid transparent;
            border-top: 1.6875rem solid #ffffff;
            z-index: 2;
        }

        dt:after {
            content: "";
            position: absolute;
            bottom: -1.875rem;
            left: 50%;
            margin-left: -1.375rem;
            border-right: 1.1875rem solid transparent;
            border-left: 1.1875rem solid transparent;
            border-top: 1.875rem solid #e4ecf5;
            border-bottom: 0;
            z-index: 1;
        }


        dd {
            position: relative;
            font-size: 1.125rem;
            font-weight: 700;
        }

        dd:first-of-type {
            display: flex;
            align-items: top;
            justify-content: center;
        }

        dd:first-of-type:before {
            display: block;
            content: "";
            margin-top: 0.625rem;
            margin-right: 0.9375rem;
            width: 2rem;
            height: 2rem;
            background: url(/images/Direct/direct_i-solution.svg) no-repeat;
            background-size: 100% auto;
            flex-shrink: 0;
        }

        dd:last-of-type {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 13.75rem;
        }

        /*dd:last-of-type:after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: calc(100%);
            height: 13.75rem;
            margin-left: -2.5rem;
            background: url(/images/Direct/direct_img_bg-circle.svg) no-repeat;
            background-size: contain;
            background-position: right center;
            z-index: 1;
        }*/

        dd:last-of-type>img {
            position: relative;
            height: 100%;
            z-index: 2;
        }

        /*.solution-img01 {
            width: 12.375rem;
        }

        .solution-img02 {
            width: 8.5rem;
        }

        .solution-img03 {
            width: 10.9375rem;
        }*/

    }

    .feature {
        overflow: hidden;

        ol {
            counter-reset: number 0;
        }

        ol>li dt:before {
            counter-increment: number 1;
            content: counter(number);
            margin-right: 1rem;
            font-family: Century Gothic, sans-serif;
            font-weight: 100;
            font-style: normal;
            font-size: 3.75rem;
            color: #6FBEE6;
            line-height: 1;
        }

        ol>li:nth-child(even) dt:before {
            right: 0;
        }

        dl {
            position: relative;
            z-index: 3;
        }

        dt {
            display: inline-flex;
            font-size: 1.25rem;
        }

        dd {
            clear: both;
        }

        ol>li>div:last-child {
            position: relative;
        }

        ol>li>div:last-child:before {
            position: absolute;
            top: 50%;
            left: 0;
            content: "";
            width: calc(100% + 20rem);
            height: 15rem;
            margin-top: -7.5rem;
            background: url(/images/Direct/direct_img_bg-circle.svg) no-repeat;
            background-position: center center;
            background-size: contain;
            z-index: 1;
        }

        ol>li:nth-child(even)>div:last-child:before {
            margin-left: -20rem;
            margin-right: auto;
        }

        ol>li>div:last-child>img {
            position: relative;
            z-index: 2;
        }

    }

    .step {

        li {
            position: relative;
        }

        li:not(:last-child):after {
            position: absolute;
            bottom: -2.5rem;
            left: 50%;
            display: inline-block;
            content: '';
            width: 0;
            height: 0;
            margin-left: -2.375rem;
            border-style: solid;
            border-right: 2.375rem solid transparent;
            border-left: 2.375rem solid transparent;
            border-top: 1.25rem solid #76a0cc;
            border-bottom: 0;
        }

        dt {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 1.125rem;
            text-align: center;
        }

        dd:first-of-type {
            text-align: center;
        }

        dd:first-of-type>img {
            display: inline-block;
            margin: 0 auto;
            width: 11.25rem;
        }

    }

    .footer {
        position: inherit;
    }

    @media (min-width: 768px) {

        h2 {
            font-size: 1.875rem;
            line-height: 3.125rem;
        }

        h3 {
            font-size: 1.3125rem;
        }

        .mainvisual {
            height: 36rem;

            &:before {
                height: 36rem;
                background-size: auto 100%;
                background-position: 50% 0;
            }

            h1 {
                font-size: 3rem;
                line-height: 5.04rem;
            }

            .button {
                width: 17rem;
            }
        }

        .solution {
            li {
                display: flex;
                justify-content: center;
                align-items: stretch;
            }

            dl {
                display: flex;
                flex-direction: column;
            }

            dd:first-of-type {
                flex: 2;
            }

            dd:last-of-type {
                flex: 2;
            }
        }

        .inqu {
            .button {
                width: 20rem;
            }
        }

        .feature {
            ol>li>div:last-child:before {
                top: 50%;
                width: calc(100% + 40rem);
                height: 30rem;
                margin-top: -15rem;
            }

            ol>li:nth-child(even)>div:last-child:before {
                margin-left: -40rem;
                margin-right: auto;
            }
        }

        .step {

            li:not(:last-child):after {
                top: 50%;
                right: -30%;
                bottom: auto;
                left: auto;
                margin-top: -0.9375rem;
                margin-left: inherit;
                border-top: 1.875rem solid transparent;
                border-bottom: 1.875rem solid transparent;
                border-left: 1rem solid #76a0cc;
                border-right: 0;
            }

            dt {
                height: 4.5rem;
            }
        }

        .footer {

            li:first-child {
                position: relative;
                padding-right: 24px;
            }

            li:first-child:after {
                position: absolute;
                top: 0;
                right: 0;
                content: '|';
            }
        }
    }

    @media (min-width:768px) and (max-width:1023.999px) {

        .solution {
            dt {
                font-size: 0.875rem;
                line-height: 1.47rem;
            }
        }

        .footer .footer-inner {
            flex-direction: column-reverse;
            height: 130px;
            padding-top: 32px;
        }

        .footer .footer-inner .copyright {
            padding-bottom: 32px;
        }
    }

    @media (min-width:768px) and (max-width:1279.999px) {

        .mainvisual,
        .solution,
        .about,
        .feature,
        .step,
        .inqu {
            .container {
                width: 90%;
            }
        }

        .solution {
            dt {
                max-width: 19.375rem;
                width: 100% !important;
            }
        }

    }


    @media (min-width: 1024px) {

        .mainvisual {

            p {
                font-size: 1.125rem;
            }
        }

        .solution {

            dt {
                width: 19.375rem;
            }

            dd:first-of-type:before {
                width: 3.125rem;
                height: 3.125rem;
            }

        }

        .feature {

            ol>li dt:before {
                margin-right: 1.5625rem;
                font-size: 7.5rem;
            }

            dt {
                align-items: flex-end;
                font-size: 1.5rem;
            }
        }

        .step {

            dd:first-of-type {
                height: 11.25rem;
            }
        }
    }

    @media (min-width: 1280px) {
        .xl\:block {
            display: inherit;
        }

        .container {
            max-width: 1280px;
        }

        .mainvisual {
            height: 38rem;

            &:before {
                height: 48rem;
                background-size: auto 100%;
                background-position: 55% 0;
            }
        }

        .solution {
            margin-top: 90px;
        }

        .step {

            li:not(:last-child):after {
                right: -28%;
                margin-top: -1.1875rem;
                border-top: 2.375rem solid transparent;
                border-bottom: 2.375rem solid transparent;
                border-left: 1.25rem solid #76a0cc;
            }
        }

    }

    @media (min-width: 1281px) {
        .xxl\:block {
            display: inherit;
        }
    }

}