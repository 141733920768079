.mbx-search_conditions {
  @apply flex items-center;
  padding: 16.5px 16px;
  border-top: solid 1px;
  border-bottom: solid 1px;
  @apply border-gray-300;

  &__header {
    @apply text-14_20 font-bold;
    white-space: nowrap;
  }

  &__content {
    @apply mx-16;
  }

  &__title {
    @apply mb-2 font-bold;
    font-size: 11px;
    line-height: 16px;
  }

  &__text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 10px;
    line-height: 15px;
  }

  &__ui {
    @apply flex items-center;
    margin: 0 0 0 auto;
  }

  &__button {
    width: 104px;
    white-space: nowrap;

    &:not(:first-child) {
      @apply ml-8;
    }
  }
}
