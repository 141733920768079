.mbx-user-item {
  font-size: 14px;
  line-height: 56px;
  height: 56px;
  cursor: pointer;
  &.bold {
    font-weight: 700;
  }
  &.disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.15;
  }
  @apply p-0 #{!important};
  &:hover {
    @apply bg-gray-50 text-red-700;
  }
  a {
    @apply px-24 #{!important};
    display: block;
  }
  span {
    position: relative;
    &.update {
      &::after {
        content: '';
        display: block;
        @apply bg-red-700;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        right: -10px;
      }
    }
  }
}
