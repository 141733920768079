.mbx-modal-default--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.mbx-modal-default--inner {
  @apply inline-flex sm:flex-col-reverse;
  transform: translateX(32px);
  max-height: 95vh;
  .mbx-modal-default--center & {
    transform: translateX(0);
  }
}

.mbx-modal-default--content {
  @apply bg-white text-center overflow-y-auto;
}

.mbx-modal-default--close {
  margin: -8px 0 0 8px;
  @apply sm:m-0;
}
.mbx-modal-default--closeIcon {
  position: sticky;
  top: 8px;
  .mbx-modal-default--center & {
    position: absolute;
    top: -8px;
    right: -48px;
    transform: translateX(0px);
    @apply sm:right-0 sm:-top-48;
  }
}

// .mbx-modal-default--overlay {
//   position: fixed;
//   z-index: 100;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100vh;
//   background: rgba(0, 0, 0, 0.75);
// }
