  section.page {
    @apply min-h-screen;
    @apply relative;
    @apply pb-121;
    @apply sm:mb-8;
    @apply sm:pb-211;
    &.preview {
      @apply bg-gray-800;
    }
    .mbx-top-preview {
      @media screen {
        .page_columns {
          display: none;
        }
      }
    }
  }
