$breakpoints: (
  'sm': 'screen and (max-width:767px)',
  'md': 'screen and (min-width:768px) and (max-width:1023px)',
);
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@media screen and (max-width: 767px) {
  .sp-none {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .sp-only {
    display: none;
  }
}

.top-modal {
  border-radius: 4px;
  @include mq(sm) {
    border-radius: 0px;
  }
}
.top-page_modal {
  .sp-modal-btn {
    width: 220px;
    height: 46px;
    margin-top: 48px;
    .button {
      height: 100%;
    }
  }
  &_inner {
    width: 780px;
    height: 720px;
    @include mq(md) {
      width: 76.246vw;
      height: 70.381vw;
    }
    @include mq(sm) {
      width: 100vw;
      height: auto;
      padding: 0 27.5px 180px;
    }
  }
  .control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    i {
      color: #ffffff;
      font-size: 21px;
    }
  }
  .left-control {
    left: -50px;
    @include mq(md) {
      left: -4.888vw;
    }
  }
  .right-control {
    right: -50px;
    @include mq(md) {
      right: -4.888vw;
    }
  }
  .inactive {
    i {
      opacity: 0.35;
    }
  }
  .contents {
    color: #636665;
    line-height: 1;
    .heading {
      padding-top: 49px;
      @include mq(md) {
        padding-top: 4.79vw;
      }
      @include mq(sm) {
        padding-top: 48px;
      }
      span {
        display: inline-block;
        border-bottom: 1px solid #636665;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.05em;
        line-height: 1.2;
        @include mq(md) {
          font-size: 1.466vw;
        }
        @include mq(sm) {
          font-size: 13px;
        }
      }
    }
    .title {
      margin-top: 25px;
      font-size: 21px;
      font-weight: bold;
      letter-spacing: 0.08em;
      line-height: 38px;
      @include mq(md) {
        margin-top: 2.444vw;
        font-size: 2.053vw;
        line-height: 3.715vw;
      }
      @include mq(sm) {
        margin-top: 22px;
        font-size: 19px;
        letter-spacing: 0;
        line-height: 35px;
      }
    }
    .text {
      margin-top: 27px;
      font-size: 15px;
      letter-spacing: 0.05em;
      line-height: 34px;
      @include mq(md) {
        margin-top: 2.639vw;
        font-size: 1.466vw;
        line-height: 3.324vw;
      }
      @include mq(sm) {
        margin-top: 16px;
        letter-spacing: 0.3px;
        line-height: 29px;
        text-align: center;
      }
    }
    .modal-btn {
      position: absolute;
      bottom: 50px;
      left: 50%;
      width: 226px;
      height: 44px;
      transform: translateX(-50%);
      @include mq(md) {
        bottom: 4.888vw;
        width: 22.092vw;
        height: 4.301vw;
      }
      .label {
        @include mq(md) {
          font-size: 1.369vw !important;
        }
      }
    }
  }
  .modal {
    &-1_1 {
      .graph-area {
        display: flex;
        justify-content: space-between;
        width: 500px;
        margin: 25px auto 0;
        @include mq(md) {
          width: 48.876vw;
          margin: 2.444vw auto 0;
        }
        @include mq(sm) {
          width: 320px;
          margin: 30px auto 0;
        }
        .graph-back {
          background-size: contain;
        }
      }
      .graph {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 190px;
        height: 190px;
        margin-right: auto;
        margin-left: auto;
        border-radius: 50%;
        color: #e96645;
        font-size: 25px;
        font-weight: bold;
        @include mq(md) {
          width: 18.573vw;
          height: 18.573vw;
          font-size: 2.444vw;
        }
        @include mq(sm) {
          width: 140px;
          height: 140px;
          font-size: 18px;
        }
      }
      .comments {
        display: flex;
        justify-content: space-between;
        width: 620px;
        margin: 17px auto 0;
        @include mq(md) {
          width: 60.606vw;
          margin: 1.662vw auto 0;
        }
        @include mq(sm) {
          width: 320px;
          margin: 20px auto 0;
        }
        p {
          width: 50%;
          font-size: 13px;
          font-weight: bold;
          @include mq(md) {
            font-size: 1.271vw;
          }
          @include mq(sm) {
            width: 140px;
            line-height: 20px;
          }
        }
      }
      .reference {
        margin-top: 29px;
        font-size: 11px;
        letter-spacing: 0.03em;
        @include mq(md) {
          margin-top: 2.835vw;
          font-size: 1.075vw;
        }
        @include mq(sm) {
          margin-top: 50px;
          font-size: 11px;
          line-height: 17px;
        }
      }
    }
    &-1_2,
    &-1_3 {
      .main-bnr {
        width: 92.4%;
        margin: 20px auto 0;
        @include mq(md) {
          margin: 1.955vw auto 0;
        }
        @include mq(sm) {
          width: 100%;
          height: 180px !important;
          margin: 30px auto 0;
          border-radius: 5px;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
        }
      }
    }
    &-2_1 {
      .flow {
        width: 440px;
        margin: 30px auto 0;
        @include mq(md) {
          width: 40vw;
          margin: 2.933vw auto 0;
        }
        @include mq(sm) {
          width: 100%;
          margin: 30px auto 0;
        }
      }
    }
    &-2_2 {
      .logo-list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        align-items: center;
        margin-top: 30px;
        padding: 0 74px;
        row-gap: 40px;
        column-gap: 20px;
        @include mq(md) {
          margin-top: 2.933vw;
          padding: 0 7.234vw;
          row-gap: 3.91vw;
          column-gap: 1.955vw;
        }
        @include mq(sm) {
          grid-template-columns: repeat(3, 1fr);
          margin-top: 20px;
          padding: 0;
          row-gap: 0;
          column-gap: 15px;
        }
      }
    }
  }
}
