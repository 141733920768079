.top,
.interview,
.event {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .interview {
    padding: 76px 40px 70px;

    &-title {
      font-size: 21px;
      font-weight: bold;
      text-align: center;
    }

    &-subtitle {
      margin-top: 6px;
      @apply font-fira;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.1em;
      text-align: center;
    }

    &-list {
      margin-top: 35px;

      a {
        display: inline-block;
        transition-duration: .15s;
        width: 100%;

        &:not(:first-child) {
          margin-top: 40px;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 30.4vw;
      }

      .profile {
        margin-top: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #cccccc;

        .num {
          font-size: 15px;
        }

        .job {
          margin-left: 18px;
          font-size: 12px;
          letter-spacing: 0.5px;
        }

        .name {
          margin-left: 18px;
          font-size: 14px;
        }
      }

      .question {
        margin-top: 15px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.5px;
      }

      .answer {
        margin-top: 7px;
        font-size: 13px;
        letter-spacing: 0.4px;
        line-height: 20px;
      }
    }
  }

  .top-footer {
    padding-top: 108px;
    background-color: #f7f7f7;
    text-align: center;

    .text {
      font-size: 12px;
      font-weight: bold;
    }

    img {
      width: 184px;
      margin: 8px auto 0;
    }

    .contact {
      display: flex;
      justify-content: center;
      margin-top: 26px;

      .link {
        span {
          color: #333333;
          font-size: 14px;
          font-weight: bold;
          text-decoration: none;
        }

        &:not(:first-child) {
          margin-left: 27px;
        }
      }
    }

    &_bottom {
      margin-top: 55px;
      padding-bottom: 30px;
      background-color: #333333;
      color: #ffffff;

      .bottom-inner {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 30px;
      }

      .links {
        display: flex;
        justify-content: space-between;
        padding-right: 43px;
        padding-left: 45px;

        .link {
          span {
            @apply text-white;
            font-size: 14px;
            font-weight: unset;
          }
        }
      }

      .copyright {
        margin-top: 52px;
        font-size: 12px;
      }
    }
  }
}

.top {
  overflow: hidden;
  color: #333333;
  line-height: 1;

  .main-bnr {
    margin-top: 55px;

    .main-slide {
      position: relative;
      height: 420px;

      h3 {
        position: absolute;
        top: 22px;
        left: 6px;
        width: 100%;
        font-size: 23px;
        font-weight: bold;
        line-height: 35px;
        text-align: center;
      }

      img {
        width: 100%;
        object-fit: cover;
      }

      .btn {
        position: absolute;
        bottom: 20px;
        left: 50%;
        width: 219px;
        transform: translateX(-50%);

        button {
          height: 47px;

          span {
            font-size: 15px;
            line-height: 1;
          }
        }
      }
    }

    .mynaviworks-logo {
      display: flex;
      justify-content: end;
      width: 100%;
      margin-top: 24px;
      padding-right: 12px;

      img {
        width: auto;
      }
    }
  }

  .top-list {
    padding: 76px 40px 70px;

    .list {
      margin-top: 35px;
      transition: filter 0.2s;
    }

    .list:hover {
      filter: opacity(70%);
    }

    .list-title {
      margin-top: 22px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.4px;
    }

    .list-text {
      margin-top: 12px;
      font-size: 14px;
      letter-spacing: -0.4px;
      line-height: 24px;
    }
  }

  .event {
    padding: 76px 40px 0;

    .event-bg {
      position: relative;
      width: 100%;
      height: 170px;
      padding: 25px;
      background-position: 75% 0;
      background-size: auto 100%;
      overflow: hidden;
      z-index: 1;

      &::before {
        content: '';
        width: 100%;
        height: 182px;
        position: absolute;
        top: 0;
        left: 0;
        background: transparent linear-gradient(72deg, #FFFFFF 0%, #FFFFFF 25%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
        opacity: 0.36;
        z-index: 2;
      }

      .event-body {
        position: relative;
        z-index: 3;

        .event-title {
          font-size: 21px;
          font-weight: 700;
          z-index: 3;
        }

        .event-subtitle {
          margin-top: 6px;
          font-family: "Fira Sans", sans-serif;
          font-size: 12px;
          font-weight: 300;
          letter-spacing: .1em;
        }

        .event-text {
          margin-top: 10px;
          font-size: 15px;
          line-height: 31px;
        }

        .event-link {
          margin-top: 15px;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -.5px;
        }
      }
    }
  }

  .introduction {
    padding: 70px 40px 88px;
    background-position: center;
    background-size: cover;

    &-title {
      font-size: 21px;
      font-weight: bold;
      letter-spacing: 0.7px;
      line-height: 36px;
    }

    &-text {
      margin-top: 25px;
      font-size: 15px;
      line-height: 31px;
    }

    &-btn {
      width: 105px;
      margin-top: 21px;
      border: 1px solid #a1a1a1;
      border-radius: 4px;
      color: #636665;
      font-size: 12px;
      font-weight: bold;
      line-height: 36px;
      text-align: center;
      cursor: pointer;

      &:hover {
        @apply bg-white;
      }
    }

    &-images {
      margin-top: 30px;
    }

    &-bottom {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 30px;
    }

    .lead-img01 {
      width: 57.333vw;
      margin-right: 14px;
      float: right;
    }

    .lead-img02 {
      width: 57.867vw;
      margin-left: 14px;
      transform: translateY(-46px);
    }

    .lead-img03 {
      width: 80vw;
      margin-left: -10px;
    }

    .lead-img04 {
      position: relative;
      z-index: 1;
      width: 48vw;
      margin-top: -25px;
      margin-right: -10px;
      float: right;
    }

    //仮入れリンク
    .logo-link {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-top: 48px;

      >p {
        &:nth-child(1) {
          font-size: 14px;
          line-height: 15px;
          padding-right: 20px;
          border-right: 1px solid #a1a1a1;
        }

        &:nth-child(2) {
          font-size: 14px;
          padding-left: 20px;
          color: #0857a8;

          i {
            font-size: 22px;
            margin-left: 10px;
          }

          a {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .logo-list {
      @apply bg-white;
      margin-top: 35px;
      padding-top: 15px;
      padding-bottom: 15px;
      border-radius: 4px;
      text-align: center;

      .text {
        display: inline-block;
        padding-bottom: 4.5px;
        border-bottom: 1px solid #a1a1a1;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 15px;
      }

      li {
        display: inline-flex;
        justify-content: center;
        width: auto;
        height: 98px;

        @media screen and (min-width:650px) and (max-width: 767.99px) {
          width: 33.33%;
        }

        img {
          max-height: 100%;
          width: auto;
        }

      }
    }
  }

  .start {
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;

    p {
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 23px;
    }

    .base-button {
      width: 220px;
      margin-top: 14px;

      button {
        height: 47px;

        span {
          font-size: 15px;
          line-height: 1;
        }
      }
    }
  }

  .recruit-voice {
    position: relative;
    padding-top: 76px;
    padding-bottom: 50px;
    padding-left: 39px;
    background-color: #e2ebea;
    background-repeat: no-repeat;
    background-size: 100% auto;

    &_title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0.07em;
    }

    &_subtitle {
      margin-top: 12px;
      @apply font-fira;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.1em;
    }

    &_description {
      margin-top: 40px;
      font-size: 15px;
      letter-spacing: 0.5px;
      line-height: 31px;
    }

    &_list {
      margin-top: 50px;

      a {
        transition-duration: .15s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .item {
      display: block;

      >div {
        display: flex;
        height: 115px;
      }

      &:not(:first-child) {
        margin-top: 40px;
      }

      &-hidden {
        height: 0;
        opacity: 0;
        margin-top: 0 !important;

        >div {
          height: 0;
          opacity: 0;

          &:not(:first-child) {
            margin-top: 0;
          }
        }
      }

      img {
        width: 115px;
        height: auto;
        object-fit: cover;
      }

      &-text {
        margin-left: 22px;
        padding-top: 3px;
      }

      .name {
        font-size: 14px;
        font-weight: bold;
      }

      .text {
        display: -webkit-box;
        width: 41.867vw;
        margin-top: 14px;
        overflow: hidden;
        font-size: 13px;
        letter-spacing: 0.07em;
        line-height: 22px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
    }

    .list-more {
      margin-top: 55px;
      padding-right: 39px;
      text-align: center;

      .click-area {
        display: inline-flex;
        position: relative;
        z-index: 3;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }

      span {
        font-size: 17px;
        font-weight: bold;
        letter-spacing: -1px;
      }

      img {
        width: auto;
        height: 12px;
        margin-left: 16px;
      }

      .close {
        transform: rotate(180deg);
      }
    }
  }

  .everyone-portfolio {
    padding-top: 73px;
    padding-bottom: 72px;

    &_title {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }

    &_subtitle {
      margin-top: 15px;
      @apply font-fira;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.1em;
      text-align: center;
    }

    .portfolio-slider {
      position: relative;
      margin-top: 86px;
    }

    .finger {
      position: absolute;
      z-index: 3;
      top: -56px;
      left: 50%;
      width: 93px;
      height: 93px;
      transform: translateX(-50%);
      border-radius: 50%;
      background-color: rgba(51, 51, 51, 0.48);

      &-none {
        transition: opacity 0.3s;
        opacity: 0;
      }

      &-contents {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        padding-top: 30px;
      }

      &-icon {
        position: absolute;
        top: 38%;
        left: 42%;
        width: 20px;
      }

      &-arrow {
        width: 20px;
      }
    }

    .slide-items {
      .slick-list {
        overflow: visible !important;
      }

      .slide-item {
        .bnr-area {
          margin: 0 14px;
          padding: 16px;
          box-shadow: 0px 0px 8px 0px rgba(111, 119, 116, 0.2);

          img {
            width: 185px;
            border: 1px solid #f2f2f2;
          }
        }

        .title {
          margin-left: 20px;
          padding-top: 10px;
          font-size: 16px;
          letter-spacing: 0.01em;
        }

        .user-info {
          display: flex;
          align-items: center;
          margin-top: 16px;
          margin-left: 20px;

          img {
            width: 68px;
            height: 68px;
            border-radius: 50%;
          }

          .name {
            margin-left: 18px;
            font-size: 15px;
            font-weight: bold;
          }

          .job {
            margin-top: 14px;
            margin-left: 18px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .questions {
    padding-top: 73px;

    &-title {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }

    &-subtitle {
      margin-top: 15px;
      @apply font-fira;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.1em;
      text-align: center;
    }

    &-contents {
      margin-top: 44px;
      padding: 0 40px;

      .content:not(:first-child) {
        margin-top: 36px;
      }

      .q-num {
        padding-bottom: 6px;
        border-bottom: 1px solid #a1a1a1;
        font-family: 'Fira Sans', sans-serif;
        text-align: center;
      }

      .question {
        margin-top: 19px;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 25px;
        text-align: justify;
      }

      .answer {
        margin-top: 10px;
        font-size: 15px;
        letter-spacing: 0.55px;
        line-height: 24px;
        text-align: justify;
      }
    }

    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 72px;

      i {
        @apply text-button-iconButton-red;
        font-size: 23px;
      }

      p {
        margin-left: 14px;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
}
