.mbx-setting-edit {
  &_bg-alpha {
    background: url('/assets/images/profile/bg_alpha.png') no-repeat center;
    background-size: cover;
    div {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .sp_only {
    display: none;
  }
  .sp_none{
    display: block !important;
  }
  input[type=file] {
    width: 1px;
    height: 1px;
  }
  &-preview-wrapper {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1023px) {
  .mbx-setting-edit {
  }
}

@media screen and (max-width: 767px) {
  .mbx-setting-edit {
    &_copy-url {
      width: 100% !important;
    }
    &-preview-wrapper {
      justify-content: flex-end !important;
    }
    .sp_only {
      display: block;
    }
    .sp_none {
      display: none !important;
    }
    .sp-edit_btn {
      .base-button {
        width: 100%;
      }
    }
  }
}
