.mbx-message_form_sent {
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 0;
  display: flex;

  &-thumb {
    img {
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }
  }

  &-body {
    flex: 1;
    header {
      display: flex;
      align-items: center;
    }
    a {
      cursor: pointer;
    }
  }
}

