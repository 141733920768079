@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Noto+Sans:ital@1&display=swap');

@media print {
  section.page {
    font-family: 'Noto Sans', sans-serif;
    font-family: 'Noto Sans JP', sans-serif;
    height: 100%;
    padding: 0;
    &.preview {
      background: none !important;
    }
    &.print {
      width: 840px;
      margin: auto;
      .mb-contents {
        width: 100% !important;
      }
    }
    .header-image {
      width: 680px;
      height: 136px !important;
      margin: 0 auto;
      // &_inner {
      //   height: 100%;
      //   background-size: auto 100%;
      // }
    }
    .pdf-none {
      display: none;
    }
    header {
      display: none;
    }
    footer {
      display: none;
    }
    .header-title {
      display: none;
    }
    .mbx-preview-header {
      box-shadow: none;
      width: auto;
      &_inner {
        display: block;
        width: 680px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 1;
        border: none;
        min-height: unset;
        padding-bottom: 24px;
        img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
  .folio {
    display: none !important;
  }
  .mbx-preview-page:not(:root) {
    page-break-before: always;
    transform: scale(1) !important;
  }

  .mbx-preview:not(:root) {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mbx-utils-stack-v--preview {
    & > * + * {
      margin-top: 0 !important;
    }
  }
  .sm\:px-24 {
    padding: 0;
  }

  .profile-preview-profile {
    flex-direction: unset;
    &__thumb {
      margin-right: 24px;
    }
  }

  //PFトップ
  .mbx-tab_navigation {
    margin-top: 24px;
    border-bottom: 1px solid black;
    &__list {
      display: none;
    }
  }

  .portfolio-card {
    .title-area {
      display: none;
    }
  }
  .mbx-top-preview {
    @apply mx-80;
    .introduction {
      margin-top: 32px;
      p {
        font-size: 12px;
        line-height: 20px;
        &:nth-child(2) {
          margin-top: 4px;
        }
      }
    }
    .personal {
      margin-top: 46px;
      > div {
        break-inside: avoid;
      }
      .questionnaire {
        margin-top: 16px;
        break-inside: avoid;
        p {
          font-size: 12px;
          line-height: 20px;
          &:nth-child(2) {
            margin-top: 4px;
          }
        }
      }
    }

    &-works {
      width: 680px;
      margin: 30px auto 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;

      li {
        margin: 0;
        break-inside: avoid;
        .thumbnail-holder {
          height: 123px !important;
        }
        .categoly-area {
          display: none;
        }
      }
    }
    .skills {
      margin-top: 32px;
      .title {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 12px;
      }
      .skill {
        margin-top: 0;
        .mbx-skill-box {
          h3 {
            font-size: 10px;
            line-height: 15px;
          }
          > div {
            margin-top: 0;
            li {
              width: 7px;
              height: 10px;
            }
            p {
              font-size: 10px;
              line-height: 15px;
            }
          }
        }
      }
    }
    .experience {
      margin-top: 40px;
      .title {
        font-size: 11px;
        line-height: 15px;
      }
      .text {
        margin-top: 12px;
        font-size: 10px;
        line-height: 15px;
      }
    }
  }

  //PF詳細プレビュー
  .details-preview {
    .header-title {
      display: none;
    }
    .mb-contents {
      width: 680px;
    }
    .img-main {
      width: 100% !important;
      height: 382px !important;
      margin: 0 !important;
    }
    .overview {
      margin: 0;
      padding: 24px 0 20px;
      &-tag {
        font-size: 11px;
        line-height: 16px;
      }
      &-title {
        font-size: 21px;
        line-height: 32px;
      }
      &-link {
        font-size: 10px;
        line-height: 15px;
        i {
          display: none;
        }
      }
    }
    .main {
      width: 450px;
    }
    .menu {
      width: 166px;
    }
    .details-left {
      padding-top: 32px;
      .list {
        margin: 0;
        break-inside: avoid;
        &:not(:first-child) {
          margin-top: 24px;
        }
        .title {
          margin: 0;
        }
        p {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .details-right {
      padding-top: 32px;
      .title {
        margin: 0;
      }
      > div {
        p {
          font-size: 10px;
          line-height: 15px;
        }
        &:not(:first-child) {
          .title {
            margin-top: 16px;
          }
        }
      }
    }
    .preview-img {
      width: 100%;
      margin-bottom: 0;
      &_item {
        page-break-before: always;
      }
      > div {
        margin: 0;
      }
      .mbx-works-preview-image {
        width: 624px;
        margin: 0 auto;
        &_style-v-2 {
          grid-row-gap: 38px;
          row-gap: 38px;
        }
        &_style-2 {
          grid-row-gap: 20px;
          row-gap: 20px;
        }
        &_style-4,
        &_style-8 {
          grid-column-gap: 20px;
          column-gap: 20px;
          grid-row-gap: 28px;
          row-gap: 24px;
        }
        &_style-8 {
          row-gap: 6px;
          .text {
            margin-top: 4px;
            line-height: 14px;
          }
        }
        > div {
          break-inside: avoid;
        }
        .work {
          break-inside: avoid;
        }
      }
      .mbx-image_responsive_inner,
      .mbx-image_mobiles_preview {
        width: 624px;
        margin: 0 auto;
        img {
          height: unset !important;
        }
      }
      .text {
        font-size: 10px;
        line-height: 15px;
      }
    }
    .embedThumbnail {
      height: auto !important;
      img {
        max-height: 416px;
        object-fit: cover;
      }
    }
  }
  .mbx-top-preview-works {
    break-inside: avoid;
  }

  //職務経歴書
  .mbx-career-title {
    page-break-inside: avoid;
  }

  .mbx-career-history-project {
    page-break-inside: avoid;
  }

  .mbx-preview-fix-btn-area {
    display: none !important;
  }
}
