.mbx-preview-header {
  position: relative;
  z-index: 1;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  &_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    min-height: 80px;
    img {
      width: 280px;
      height: 40px;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1023px) {
  .mbx-preview-header {
    &_inner {
      width: 100%;
      padding: 14.5px;
    }
  }
}
