.mbx-anime-notification {
  &-enter {
    opacity: 0;
  }
  &-enter-done {
    opacity: 1;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.3s;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 0.3s;
  }
}
