.footer {
  @apply border-t border-gray-300 bg-white text-text w-full absolute bottom-0;
  .footer-inner {
    justify-content: space-between;
    height: 120px;
    padding: 32px 24px 0;
    @apply flex sm:flex-col-reverse sm:pt-30 sm:h-auto;
    .copyright {
      font-size: 11px;
      @apply font-en sm:text-9_16 sm:mt-38 sm:pb-58;
    }
    .footer-logo {
      width: 120px;
      img {
        width: 100%;
        height: auto;
      }
    }
    ul {
      font-size: 12px;
      @apply flex sm:flex-col sm:text-body_2;
      li {
        &:not(:first-child) {
          margin-left: 24px;
          @apply sm:ml-0 sm:mt-28;
        }
      }
    }
  }
}
