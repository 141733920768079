@import '../../mixins/mq';
.register {
  .mb-contents {
    min-height: calc(100vh - 137px);
  }
  .mail-text {
    .title {
      @include mq('sm') {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
