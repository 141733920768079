.mbx-formCheckbox {
  @apply block;
  span {
    @apply block;
  }
  .mbx-icon {
    @apply duration-300 transform -translate-y-2/4 text-24_24;
    @apply text-white;
    display: block;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 0;
    opacity: 0;
  }
  input[type='checkbox'] {
    display: none;
    & + span::before {
      @apply duration-300 block rounded-formContainer absolute border border-border-form bg-white transform -translate-y-2/4;
      content: '';
      top: 50%;
      left: 0;
      width: 23px;
      height: 23px;
    }

    &:checked + span::before {
      @apply border-red-700 bg-red-700 #{!important};
    }
    &:checked + span .mbx-icon {
      display: block;
      opacity: 1;
    }
  }

  .mbx-state--hover &,
  &:hover,
  .mbx-state--focus &,
  &:focus {
    input[type='checkbox'] + span::before {
      @apply bg-red-100 border-red-700;
    }
  }
}
