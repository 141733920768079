@import '../../mixins/mq';

.login {
  &-form {
    .btn {
      width: 100%;
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    @include mq('sm') {
      position: static;
    }
  }
}
