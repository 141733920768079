.mbx-message_form {
  height: 80px;
  display:flex;
  margin: 8px 0 0;
  align-items: center;
  &-textarea {
    flex: 1;
    textarea {
      outline: 0;
      width: 100%;
    }
  }
}

