@import '../../mixins/mq';

.block-section {
  .modal-btn {
    width: 308px;
    .button {
      height: 100%;
    }
  }
  .warning {
    @include mq(sm) {
      margin-right: calc(((100vw - 100%) / 2) * -1);
      margin-left: calc(((100vw - 100%) / 2) * -1);
    }
  }
}
