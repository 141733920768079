.mbx-admin_tooltip {
  background: #424242;
  border-radius: 4px;
  width: 280px;
  color: white;
  position: absolute;
  padding: 16px;
  @apply text-10_16;
  display: none;
  z-index: 2;
}
