.profile-edit {
  .sp_only {
    display: none;
  }
  .sp_none{
    display: block !important;
  }
  .questionnaire {
    .question {
      font-weight: 700;
    }
  }
  .skills,
  .experience {
    .link {
      margin-top: 8px;
      font-size: 11px;
      line-height: 14px;
    }
  }
  &-preview-wrapper {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1023px) {
  .profile-edit {
    .prev-link {
      width: 91px;
    }
    .md\:flex-col-reverse {
      flex-direction: column;
      .main {
        order: 1;
      }
      .menu {
        order: 2;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .profile-edit {
    &-preview-wrapper {
      justify-content: flex-end !important;
    }
    .sp_only {
      display: block;
    }
    .sp_none {
      display: none !important;
    }
    .sp-edit_btn {
      .base-button {
        width: 100%;
      }
    }
    .header-image {
      height: 88px;
    }
    .portfolio-header_edit {
      .inner {
        padding-top: 24px;
      }
      .edit-area {
        display: block;
        margin-bottom: 24px;
        .user-img {
          width: 80px;
          height: 80px;
          margin: 0 auto 48px auto;
        }
      }
      .input-layout {
        width: 100%;
        &:last-child {
          margin-top: 16px;
        }
      }
    }
    .introduction {
      .text-area {
        height: 263px;
        textarea {
          height: 100%;
        }
      }
    }
    .questionnaire {
      margin-top: 64px;
      margin-bottom: 0px;
      padding-bottom: 64px;
      border-bottom: 1px solid#E0E0E0;
      .title {
        margin-bottom: 10px;
      }
    }
    .skills {
      margin-top: 32px;
      padding-left: 0;
    }
    .experience {
      padding-bottom: 48px;
      padding-left: 0;
    }
  }
}
