@import '../../App.scss';
@media screen and (max-width: 767px) {
  @import 'layout_sp.scss';
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  @import 'layout_tb.scss';
}

@media screen and (min-width: 1024px) {
  @import 'layout_pc.scss';
}

@import 'animation.scss';

.slick-slide div {
  outline: none;
}

.event-bg {
  background: url(/images/matchbox-event-bn.jpg) no-repeat;
  background-size: 100% auto;
}
