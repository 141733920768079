.mbx-loader {
  transform: rotate(-90deg);
  .mbx-loader-border {
    @apply stroke-current text-red-700;
    fill: transparent;
    stroke-width: 4;
    animation: circle 1.5s infinite;
  }
  .mbx-loader-base {
    @apply stroke-current text-red-300;
    fill: transparent;
    stroke-width: 4;
  }
}

@keyframes circle {
  0% {
    stroke-dasharray: 0 151;
  }
  99.9%,
  to {
    stroke-dasharray: 151 151;
  }
}


.mbx-loader_sm {
  width: 16px;
  height: 16px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  background: linear-gradient(0deg, rgba(255,255,255,0.1) 33%, rgba(255,255,255,1) 100%);
  animation: spin .8s linear 0s infinite;
}
.mbx-loader_sm_core
{
  @apply bg-gray-800;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.mbx-loader_sm_2 {
  width: 16px;
  height: 16px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  background: linear-gradient(0deg, rgba(10,88,168,0.1) 33%, rgba(10,88,168,1) 100%);
  animation: spin .8s linear 0s infinite;
}
.mbx-loader_sm_2_core
{
  @apply bg-gray-200;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}