.mbx-user_module {
  @apply bg-white;
  position: relative;
  width: 960px;
  min-height: 196px;
  padding: 24px 64px 24px 24px;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  &.is-unread {
    @apply bg-blueGray-50 border-blueGray-200;
  }
  &.disable {
    @apply bg-gray-300 border-gray-500;
  }

  &:not(:first-child) {
    @apply mt-24;
  }

  &__arrow {
    @apply text-blue-700;
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    height: 23px;
    margin: auto 0;
    font-size: 23px;
  }

  .basic-info {
    @apply flex items-center;

    .motivation {
      @apply flex justify-center items-center h-24 font-bold bg-red-100 text-10_16;
      padding: 0 11px 0 4px;

      &__icons {
        @apply flex items-center;
      }

      &__icon {
        flex-shrink: 0;
        width: 11px;
        height: 16px;
        object-fit: contain;
      }

      &__text {
        @apply ml-3;
      }
    }

    &__items {
      @apply font-bold text-blueGray-700 ml-16;
      font-size: 11px;
      line-height: 16px;
    }

    &__item {
      &:not(:first-child) {
        &::before {
          @apply font-normal mx-4;
          content: '/';
        }
      }
    }
  }

  .user-summary {
    @apply flex items-center mt-14;

    &__name {
      @apply flex items-center font-bold;
      font-size: 14px;
      line-height: 20px;

      &.is-unread {
        &::before {
          @apply bg-red-700 mr-8;
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }

    &__icon {
      @apply mr-8;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__items {
      @apply ml-16;
      font-size: 11px;
      line-height: 16px;

      &.is-unread {
        @apply font-bold;
      }
    }

    &__item {
      &:not(:first-child) {
        &::before {
          @apply font-normal mx-4;
          content: '/';
        }
      }
    }
  }

  .user-info {
    @apply flex items-start mt-14;

    &__left {
      min-width: 348px;
    }

    &__right {
      @apply ml-16 mr-8;
      flex-shrink: 0;
      // min-width: 266px;
      // min-width: 368px;
    }

    &__image {
      position: relative;
      flex-shrink: 0;
      width: 112px;
      height: 63px;
      margin: -10px 0 0 auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--blank {
        border: 1px solid;
        @apply border-gray-300;

        &::before,
        &::after {
          @apply bg-gray-300;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: -9px;
          width: 128px;
          height: 1px;
          margin: auto;
        }

        &::before {
          transform: rotate(29deg);
        }

        &::after {
          transform: rotate(-29deg);
        }
      }
    }
  }

  .user-info-column {
    @apply flex;

    .user-info-item:not(:first-child) {
      @apply ml-10;
    }
  }

  .user-info-item {
    @apply flex items-start;
    font-size: 10px;
    line-height: 16px;

    &__header,
    &__text {
      display: block;
    }

    &__header {
      white-space: nowrap;

      .text {
        display: inline-block;
      }

      &--w20 {
        .text {
          width: 20px;
        }
      }

      &--w42 {
        .text {
          width: 42px;
        }
      }

      &--w72 {
        .text {
          width: 72px;
        }
      }

      &--w112 {
        .text {
          width: 112px;
        }
      }

      &::after {
        content: '：';
      }
    }

    &__text {
      &--omit {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__link {
      @apply flex items-start text-blue-700;

      .mbx-icon {
        &::before {
          font-size: 16px;
        }
      }
    }
  }

  .account-info {
    @apply text-gray-600 mt-12;
    font-size: 10px;
    line-height: 12px;

    &__item {
      @apply text-gray-600;

      &:not(:first-child) {
        @apply ml-6;
      }
    }
  }

  .open-close-button {
    @apply font-bold text-blue-700 flex items-center;
    position: absolute;
    right: 65px;
    bottom: 18px;
    font-size: 10px;
    line-height: 12px;

    &__icon,
    &__text {
      display: block;
    }

    &__text {
      @apply ml-2;
    }
  }

  .portfolio {
    @apply grid grid-cols-5 gap-x-18 gap-y-16 mt-12;

    .portfolio-item {
      position: relative;
      width: 160px;
      height: 90px;

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--blank {
        border: 1px solid;
        @apply border-gray-300;

        &::before,
        &::after {
          @apply bg-gray-300;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: -12px;
          width: 182px;
          height: 1px;
          margin: auto;
        }

        &::before {
          transform: rotate(29deg);
        }

        &::after {
          transform: rotate(-29deg);
        }
      }
    }
  }

  .text-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &__second__line {
      -webkit-line-clamp: 2;
    }
  }
}
