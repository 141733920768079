.register {
  &-btn {
    .btn {
      width: 100%;
      .button {
        height: 100%;
      }
    }
  }
}
