@import '../../../mixins/mq';

.mbx-image_responsive {
  width: 100%;
  height: 100%;
  position: relative;
  &_cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &_inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &_pc {
    background: url(/images/mockup-mobile-device-pc.png) no-repeat center center;
    background-size: cover;
    width: calc(100% * 392 / 576);
    height: calc(100% * 253 / 384);
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 14px 54px 35px 10px;
    font-size: 32px;
  }
  &_tablet {
    background: url(/images/mockup-mobile-device-tablet.png) no-repeat center center;
    background-size: cover;
    width: calc(100% * 175 / 576);
    height: calc(100% * 244 / 384);
    top: 51.5%;
    left: 24.5%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 14px 53px 35px 6px;
    font-size: 25px;
  }
  &_sp {
    background: url(/images/mockup-mobile-device-sp.png) no-repeat center center;
    background-size: cover;
    width: calc(100% * 75 / 576);
    height: calc(100% * 144 / 384);
    top: 64.5%;
    left: 35%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 20px 10px 23px;
    font-size: 16px;
  }
  &_input {
    &:hover,
    &:active {
      background: #f4f9fe;
      color: #a3bcd6;
    }
    background: #eeeeee;
    width: 100%;
    height: 100%;
    color: #c4c4c4;
  }
  &_input-file {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
  }
}
