@import '../../mixins/mq';
.mbx-offer {
  display: flex;
  align-items: center;
  min-height: 64px;
  @include mq(sm) {
    overflow-x: hidden;
  }
  &-btn {
    width: 69px;
    @include mq(sm) {
      width: 110px;
    }
    @include mq(sms) {
      width: 30%;
    }
    .button {
      padding: 0;
      border-width: 1px;
      @include mq(sm) {
        height: 36px;
      }
    }
  }
  .hover-area {
    position: relative;
    .offer-detail {
      display: none;
      position: absolute;
      bottom: 33px;
      left: 92px;
      width: 440px;
      z-index: 3;
      .title {
        min-width: 50px;
      }
    }
    @include mq(lg) {
      &:hover {
        .offer-detail {
          display: flex;
        }
        .name {
          @apply text-blue-600;
        }
        .icon {
          @apply text-blue-500;
        }
      }
    }
  }
  .name {
    @include mq(sm) {
      width: 68vw;
    }
  }
  .status {
    @include mq(sm) {
      width: 100%;
      margin: 0 auto;
    }
  }
}
