.mbx-pf_add_button {
  border: 1px dashed #BDBDBD;
  background: #FAFAFA;
  display: flex;
  justify-content: center;
  align-items: center;

  .mbx-icon {
    color: #C4C4C4;
  }
}

