.mbx-utils {
  &-sort {
    &-group {
      @apply cursor-pointer;
      &:hover {
        .mbx-icon {
          @apply text-gray-500;
        }
      }
    }
  }
}
