.mbx-circle-placeholder {
  width: 100%;
  height: 100%;
  // padding-top: 100%;
  display: block;
  border: 2px solid #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
