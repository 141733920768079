.mbx-type_select {
  width: 340px;
  display: flex;
  &_input-file {
    width: 1px;
    height: 1px;
    display: none;
  }
  &_inner {
    margin: auto;
    h1 {
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      margin: 0 0 1.5em;
    }

    small {
      font-size: 10px;
      display: block;
      margin: 6px 0 12px;
    }
  }
}

