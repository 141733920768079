.mbx-keyline {
  &-horizontal--thin {
    @apply border-t;
    @apply border-gray-400;
  }
  &-horizontal--bold {
    @apply border-t-2;
    @apply border-gray-800;
  }
  &-horizontal--thin {
    @apply border-t;
    @apply border-gray-300;
  }
  &-horizontal-b--thin {
    @apply border-b;
    @apply border-gray-300;
  }
  &-horizontal-b--thin-dashes {
    @apply border-b;
    @apply border-dashed;
    @apply border-gray-400;
  }
  &-horizontal-r--thin-dashes {
    @apply border-r;
    @apply border-dashed;
    @apply border-gray-400;
  }
}
