.mbxd-candidates_modal {
  .select {
    select {
      height: 36px;
      padding-right: 0;
      padding-left: 10px;
      font-size: 12px;
    }
  }
}
