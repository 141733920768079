@import '../../mixins/mq';
.invitation {
  .child {
    border-radius: 4px;
    @include mq(sm) {
      border-radius: 0;
    }
  }
  .direct-btn {
    width: 308px !important;
    @include mq(sm) {
      width: 327px !important;
    }
    @include mq(sms) {
      width: 100% !important;
    }
    .button {
      @include mq(sm) {
        height: 100%;
      }
    }
  }
}
