.modal {
  width: auto;
  height: auto;
  &-overlay {
    display: flex;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    &_center {
      align-items: center;
      .modal-inner {
        .content {
          transform: translateX(0px);
          .btn-area {
            margin-left: 0px;
          }
        }
      }
    }
  }
  &-inner {
    position: relative;
    width: 100%;
    height: auto;
    overflow: auto;
    text-align: center;
    > .content {
      display: inline-flex;
      transform: translateX(32px);
      .child {
        @apply bg-white;
      }
      .btn-area {
        margin-left: 8px;
      }
    }
    .content-center {
      transform: translateX(0);
    }
  }
  .close-icon {
    position: sticky;
    top: 8px;
    margin: 0;
    cursor: pointer;
    &_center {
      position: absolute;
      top: -8px;
      right: -48px;
      transform: translateX(0px);
    }
  }
}

@media screen and (max-width: 1123px) {
  .modal {
    &-overlay {
      &_center {
        .modal-inner {
          margin-top: -48px;
          .content {
            .btn-area {
              margin-left: 0px;
            }
          }
        }
      }
    }
    &-inner {
      .content {
        flex-wrap: wrap;
        justify-content: center;
        transform: translateX(0px);
        .child {
          display: inline-block;
          order: 2;
        }
        .btn-area {
          display: flex;
          position: sticky;
          top: 0px;
          justify-content: flex-end;
          order: 1;
          width: 100%;
          margin: 0;
          padding: 8px 0;
        }
      }
    }
    .close-icon {
      position: relative;
      top: 0;
      right: 8px;
      &_center {
        position: relative;
        top: 0px;
        right: 8px;
      }
    }
  }
}

.btn-hidden {
  display: none !important;
}
