$breakpoints: (
  'sms': 'screen and (max-width:320px)',
  'smm': 'screen and (min-width:321px) and (max-width:767px)',
  'sm': 'screen and (max-width:767px)',
  'md': 'screen and (min-width:768px)',
  'lg': 'screen and (min-width:1024px)',
);
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
