@import '../../../mixins/mq';

.mbx-image_mobiles {
  position: relative;
  &_cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &_inner {
    display: flex;
    justify-content: space-between;
    height: 295px;
    @include mq('sm') {
      height: 167px;
    }
  }

  &_device {
    position: relative;
    background: url(/images/mockup-mobile-device.png) no-repeat center center;
    background-size: cover;
    width: 140px;
    height: 295px;
    @include mq('sm') {
      width: 79px;
      height: 167px;
      padding: 19px 3px 20px;
    }
    margin: 0 12px;
    padding: 34px 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &_input {
    &:hover, &:active {
      background: #F4F9FE;
      color: #A3BCD6
    }
    background: #EEEEEE;
    width: 100%;
    height: 100%;
    color: #C4C4C4;
    font-size: 32px;
  }
  &_input-file{
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  &_close {
    color: white;
    background: #C4C4C4;
    font-size: 32px;
    position: absolute;
    border-radius: 50%;
    right: -16px;
    top: -16px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    width: 32px;
    height: 32px;
  }

  &_add {
    width: 140px;
    height: 295px;
    @include mq('sm') {
      width: 79px;
      height: 167px;
      right: calc(-79px - 24px);
      border-radius: 17px;
    }
    position: absolute;
    right: calc(-140px - 24px);
    top: 0;
    font-size: 32px;
    background: #FAFAFA;
    border: 1px dashed #BDBDBD;
    color: #C4C4C4;
    border-radius: 17px;
    &:hover, &:active {
      background: #F4F9FE;
      color: #A3BCD6;
      border-color: #A3BCD6;
    }
  }
}