.mbx-utils {
  &-stack {
    &-v {
      &--2 {
        & > * + * {
          @apply mt-2;
        }
      }
      &--8 {
        & > * + * {
          @apply mt-8;
        }
      }
      &--16 {
        & > * + * {
          @apply mt-16;
        }
      }
      &--22 {
        & > * + * {
          @apply mt-22;
        }
      }
      &--35 {
        & > * + * {
          @apply mt-35;
        }
      }
      &--48 {
        & > * + * {
          @apply mt-48;
        }
      }
    }
    &-h {
      &--8 {
        & > * + * {
          @apply ml-8;
        }
      }
      &--24 {
        & > * + * {
          @apply ml-24;
        }
      }
      &--54 {
        & > * + * {
          @apply ml-54;
        }
      }
    }
  }
}
