@import './mixins/mq';
@import './common/component/typography';
@import './common/component/keyline';
@import './common/component/matchdegree';
@import './common/component/formContainer';
@import './common/component/formRadio';
@import './common/component/formCheckbox';
@import './common/component/formToggle';
@import './common/component/formDropdown';
@import './common/component/buttonIconButton';
@import './common/component/formUtils';
@import './common/utils/stack';
@import './common/utils/sort';
@import './common/utils/hover';
@import './common/utils/indent';
@import './common/component/naviInpage';
@import './common/component/profileImage';
@import './common/component/profileImageSquare';
@import './common/component/tooltip';
@import './common/component/print';
@import './common/component/notification';
@import './common/component/buttonTextLink';
@import './common/component/formUnit';
@import './common/component/loading';
@import './common/Modal/default';
@import './common/anime/notification';
@import './common/anime/modal';
@import './common/anime/error';
@import './common/anime/img';

li.not-available {
  text-decoration: line-through;
  opacity: 0.3;
}

table.index {
  width: 100%;
  tr {
    th,
    td {
      border: 1px solid #ccc;
      padding: 5px 10px;
      a {
        text-decoration: underline;
      }
    }
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
