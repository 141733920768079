.mbx-user-menu {
  width: 288px;
  height: auto;
  border-top: 1px solid #e0e0e0;
  background: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  @apply lg:rounded-4;

  & > section {
    @apply p-24;
  }
  .mynavi {
    width: 100%;
  }
}
