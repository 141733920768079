@import '../../mixins/mq';

.mbx-uploadfile {
  position: relative;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  @include mq('lg') {
    left: 0;
    width: 960px;
    transform: translateX(0);
  }
  &-list {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 56px;
    &-button {
      flex-shrink: 0;
      justify-content: flex-end;
      .mbx-icon-External_link-thin {
        display: none;
      }
    }
  }
}
