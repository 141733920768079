.mbx-offer_status {
  position: absolute;
  top: 24px;
  right: 64px;

  .icon {
    @apply mr-2;
    width: 13.33px;
    height: 12.67px;
  }

  &__pickup-button {
    @apply flex items-center justify-center text-gray-600 bg-white font-bold;
    width: 112px;
    height: 36px;
    transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    border: solid 1px;
    border-radius: 4px;
    @apply border-gray-400;
    font-size: 10px;
    line-height: 16px;

    &:hover {
      @apply bg-gray-100;
    }
  }

  &__label {
    @apply flex items-center justify-center font-bold;
    width: 112px;
    height: 26px;
    font-size: 10px;
    line-height: 16px;

    &--pickup {
      @apply bg-success-200 text-success-700;
      height: 36px;
      border-radius: 4px;
    }

    &--consultation {
      @apply bg-attention-200 text-attention-700;
      letter-spacing: -0.1em;
    }

    &--offer {
      @apply bg-red-200 text-red-700;
    }

    &--offer2 {
      @apply bg-blue-200 text-blue-700;
      font-size: 9px;
      letter-spacing: -0.05em;
    }

    &--farewell {
      @apply bg-gray-200 text-gray-400;
    }
  }
}
