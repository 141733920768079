.portfolio-card {
  &_inner {
    position: relative;
    width: 100%;
    height: 100%;
    .edit-area {
      display: flex;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 96px;
      height: 48px;
      transition: background-color 0.2s ease-out;
      background: rgba(0, 0, 0, 0.5);
      .dragHandleSelector {
        cursor: grab;
        &:active {
          cursor: grabbing;
        }
      }
      .delete {
        cursor: pointer;
      }
      button {
        pointer-events: none;
      }
      i {
        color: #ffffff;
        font-size: 24px;
        pointer-events: none;
      }
    }
    .thumbnail-holder {
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @apply bg-gray-50;
      cursor: pointer;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: background-color 0.2s ease-out;
        background: rgba(0, 0, 0, 0);
      }
      &:hover::after {
        transition: background-color 0.2s ease-out;
        background: rgba(0, 0, 0, 0.5);
      }
      &:hover .edit-area {
        transition: background-color 0.2s ease-out;
        background: rgba(0, 0, 0, 0);
      }
      button {
        pointer-events: none;
      }
      i {
        color: #ffffff;
        font-size: 24px;
        pointer-events: none;
      }
    }
    .title-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      margin-top: 8px;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        width: 248px;
        overflow: hidden;
        font-size: 14px;
        line-height: 1.29;
      }
      .title-long {
        width: 100%;
      }
    }
    .disable {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 100%;
      cursor: pointer;
      i {
        font-size: 25px;
      }
    }
    .categoly-area {
      margin-top: 4px;
      color: #757575;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 11px;
        line-height: 1.45;
      }
    }
  }
  .is-disable {
    opacity: 0.2;
    // pointer-events: none;
  }
  .no-edit {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .portfolio-card {
    &_inner {
      .edit-area {
        display: none;
      }
    }
  }
}
