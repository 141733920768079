@import '../../mixins/mq';

.mbx-image_mobiles_preview {
  position: relative;
  &_inner {
    display: flex;
    justify-content: space-between;
    height: 338px;
    @include mq('sm') {
      height: 200px;
    }
  }

  &_device {
    position: relative;
    background: url(/images/mockup-mobile-device.png) no-repeat center center;
    background-size: cover;
    width: 160px;
    height: 332px;
    margin: 0 12px;
    padding: 30px 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @include mq('sm') {
      width: 93px;
      height: 200px;
      padding: 20px 2px 22px 2px;
    }
  }
}
