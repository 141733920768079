.mbx-formToggle {
  @apply block pl-4 pr-4 pb-6  content-center;
  &-body {
    @apply duration-300 relative bg-gray-50;
    width: 40px;
    height: 24px;
    border-radius: 100px;
    &:after {
      @apply duration-300 bg-white;
      content: '';
      position: absolute;
      z-index: 2;
      top: 2px;
      right: 18px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
  span {
    @apply block;
  }
  input[type='checkbox'] {
    display: none;
    &:checked + div {
      @apply bg-red-700 #{!important};
    }
    &:checked + div:after {
      right: 2px;
    }
  }
}
