@import '../../mixins/mq';
.direct-status {
  .child {
    border-radius: 4px;
  }
}
.stop-direct_modal {
  width: 784px;
  padding: 80px 0;
  @include mq(sm) {
    width: 100vw;
    padding-top: 48px;
  }
  .warning {
    @include mq(sm) {
      margin-right: calc(((100vw - 100%) / 2) * -1);
      margin-left: calc(((100vw - 100%) / 2) * -1);
    }
  }
  .modal-btn {
    width: 308px;
    @include mq(sm) {
      width: 327px;
    }
    .button {
      height: 100%;
    }
  }
}
