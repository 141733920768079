.mbxd-candidates_top {
  .filters {
    .select {
      select {
        height: 36px;
        padding-right: 34px;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  .table-header {
    div > {
      div {
        position: relative;
        > i {
          position: absolute;
          top: 50%;
          right: -3px;
          transform: translateY(-50%) translateX(100%);
          font-size: 12px;
        }
        > .tool-tip {
          position: absolute;
          z-index: 20;
          bottom: -12px;
          left: 50%;
          padding: 16px;
          transform: translateX(-50%) translateY(100%);
          border-radius: 4px;
          @apply bg-gray-800;
          @apply text-white;
        }
      }
    }
  }
  .mbx-tooltip--hover {
    &:hover .mbx-admin_tooltip {
      display: block;
    }
  }
}
