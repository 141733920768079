@import '../../mixins/mq';

.mbx-naviInpage {
  top: 0;
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
  &-menu {
    @apply rounded-formContainer;
    @include mq('sm') {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 150%;
      transform: scale(0.9);
      transform-origin: right top;
      transition: opacity 0.3s ease, transform 0.3s ease;
      opacity: 0;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      .is-open & {
        z-index: 1000;
        top: 89px;
        right: 32px;
        transform: scale(1);
        opacity: 1;
      }
    }
    @include mq('md') {
      position: absolute;
      top: 120px;
      right: 45px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
    @include mq('lg') {
      position: sticky;
      opacity: 1;
      pointer-events: inherit;
      box-shadow: none;
    }

    transition: opacity 100ms ease-out;
    opacity: 0;

    &.enter {
      opacity: 1;

      &-done {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }
  &-menuList {
    display: inline-block;
  }
  &-item {
    cursor: default;
    &:not(.text-navText-disabled) {
      cursor: pointer;
    }
    &-icon {
      margin-right: 2px;
      padding: 1px;
      font-size: 125%;
      vertical-align: top;
    }
  }
}
