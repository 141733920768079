.profile-preview {
  &-header {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .title-contents {
    display: flex;
    .pdf {
      width: 88px;
      &-link {
        line-height: 48px;
      }
    }
    .edit {
      width: 140px;
    }
    .base-button {
      width: 100%;
    }
  }
  &-profile {
    display: flex;
    align-items: center;
    margin-top: -20px;
    @media (max-width: 767px) {
      flex-direction: column;
      @apply sm:mb-8;
    }

    &__thumb {
      width: 128px;
      height: 128px;
      margin-right: 40px;
      @media (max-width: 767px) {
        margin-right: 0;
      }
    }
    &__inner {
      padding-top: 20px;
    }
  }
  &-wrapper {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.sp_only {
  display: none;
}
@media screen and (max-width: 1023px) {
  .profile-preview {
    .title-contents {
      .pdf {
        width: auto;
      }
      .edit {
        width: 80px;
        white-space: nowrap;
      }
      .base-button {
        width: 100%;
      }
    }
    .md\:flex-col-reverse {
      flex-direction: column;
      .main {
        order: 1;
      }
      .menu {
        order: 2;
      }
    }
    .skills {
      padding-left: 0;
    }
    .experience {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 826px) {
  .profile-preview-wrapper{
    justify-content: flex-end !important;
  }
  .sp_only {
    display: block;
  }
  .sp_none {
    display: none !important;
  }
  .sp-edit_btn {
    .base-button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .profile-preview {
    .title-contents {
      align-items: center;
      .pdf {
        margin-right: 8px;
      }
      .edit {
        .base-button {
          .button {
            height: 36px;
          }
        }
      }
    }
    &-profile {
      margin-top: -56px;
      &__thumb {
        width: 80px;
        height: 80px;
      }
      &__inner {
        padding-top: 0;
      }
    }
    .mbx-top-preview-profile__inner {
      padding-top: 0;
      span {
        display: inline-block;
        margin-top: 8px;
      }
      p {
        margin-top: 4px;
      }
    }
    .portfolio-navigation {
      margin-top: 8px;
    }
    .personal {
      margin-top: 64px;
    }
    .skills {
      margin-top: 64px;
      border-top: 1px solid#E0E0E0;
      .title {
        margin-top: 32px;
      }
    }
  }
}
