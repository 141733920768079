.password-change {
  .form {
    .btn {
      width: 100% !important;
      .button {
        height: 100%;
      }
    }
  }
}
