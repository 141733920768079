.terms-modal {
  opacity: 0;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  transition: opacity 0.3s;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  &.open {
    opacity: 1;
    z-index: 999;
  }
  &__content {
    background-color: #ffffff;
    border-radius: 4px;
    width: 50%;
    padding: 48px;
    position: absolute;
    top: 10%;
    text-align: left;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  &__title {
    padding-bottom: 10px;
    border-bottom-width: 2px;
  }
  &__company__names {
    font-size: 16px;
    padding-right: 20px;
    font-weight: 700;
  }
  &__service_names {
    font-size: 16px;
    font-weight: 700;
  }
  &__sub__title {
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 40px;
  }
  &__in__content {
    border-radius: 5px;
    border: solid 2px #c2c2c2;
    background: #f2f2f2;
    padding: 24px;
    min-height: 100px;
  }
}

.icon {
  &__button {
    cursor: pointer;
    color: #6f6f6f;
    transform: scale(1);
    transition: all 0.3s;
  }
  &__button:hover {
    transform: scale(1.3);
  }
}

.overfllow__hidden {
  overflow: hidden !important;
}

.service__table {
  width: 100%;
  border-collapse: collapse;
  thead th {
    background: #e5e5e5;
    padding: 10px;
    font-size: 12px;
  }
  tbody {
    td {
      font-size: 14px;
      padding: 10px;
      background: #fafafa;
      border-bottom: solid 2px #d7d7d7;
      &.text__center {
        text-align: center;
      }
      &.no__border {
        border-bottom: none;
      }
      img {
        height: 16px;
        width: auto;
        margin: 0 auto;
      }
    }
  }
}

.classification {
  padding: 10px 15px;
  color: #fff;
  display: block;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  border-radius: 100px;
  line-height: 12px;
  margin-bottom: 5px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &.strictly__necessary {
    background: #ccd547;
  }
  &.performance {
    background: #2c98da;
  }
  &.functional {
    background: #fa8231;
  }
  &.targeting {
    background: #fd5b65;
  }
  &.social__media {
    background: #f7b731;
  }
  &.unknown {
    background: #a5b1c2;
  }
  &.onetrust {
    background: #6cc04a;
  }
}

.sp-display {
  .classification {
    font-size: 12px;
  }
  .inner {
    width: 100%;
    .intro {
      &__text {
        text-align: left !important;
      }
    }
  }
  .service {
    &__list {
      padding: 0;
      list-style: none;
    }
    &__list__table {
      overflow: hidden;
      dt {
        float: left;
        margin-bottom: 16px;
        font-weight: bold;
        clear: both;
        width: 100px;
        font-size: 14px;
      }
      dd {
        float: left;
        margin-bottom: 16px;
        width: calc(100% - 100px);
        .classification {
          display: inline-block;
        }
        font-size: 14px;
      }
    }
    &__link__list {
      display: flex;
      justify-content: center;
      a {
        color: blue;
        font-size: 14px;
        padding: 0px 1vw;
      }
    }
  }
  .list__item {
    border-radius: 4px;
    border: solid 2px #c2c2c2;
    background: #f2f2f2;
    padding: 20px;
    margin-bottom: 30px;
  }
}
