.mbx-action-header {
  position: relative;
  z-index: 12;
  width: 100%;
  @apply bg-gray-200 border-b border-gray-300 px-16;
  &_inner {
    @apply h-56 sm:h-48;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .children{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

