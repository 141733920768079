.header {
  @apply bg-white relative border-b border-gray-300;
  .header-inner {
    display: flex;
    position: relative;
    z-index: 2;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    @apply h-79 sm:h-55 lg:w-960 pl-16 pr-8 lg:px-0 bg-white;

    .logo {
      width: 160px;

      img {
        width: 100%;
        height: auto;
      }
    }

    & > section {
      display: block;
      @apply lg:hidden #{!important};
    }

    nav {
      @apply lg:flex #{!important};
      display: none;
      align-items: center;

      ul {
        display: flex;

        li {
          color: #000000;
          font-size: 14px;
          font-weight: 700;

          &:not(:first-child) {
            margin-left: 64px;
          }
        }
      }

      .profile_icon {
        position: relative;
        width: 32px;
        margin-left: 72px;
        cursor: pointer;

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
        }

        .red_circle {
          position: absolute;
          top: 0;
          right: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #ff5a39;
        }
      }
    }
  }

  .mbx-user-menu {
    position: absolute;
    top: 80px;
    right: 0;
    transition: opacity 100ms ease-out;
    opacity: 0;
    pointer-events: none;
    @apply z-30;

    @media (min-width: 1024px) {
      left: calc(50% + (960px / 2) - 288px);
    }

    @media (max-width: 767px) {
      top: 55px;
      left: 0;
      width: 100%;
    }

    &.enter {
      opacity: 1;

      &-done {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .header-inner {
      .logo {
        width: 124px;
      }
    }
  }
}
