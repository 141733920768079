.mbx-formRadio {
  @apply block;
  span {
    @apply block;
  }
  input[type='radio'] {
    display: none;
    & + span::before {
      @apply duration-300 block absolute border border-border-form bg-white transform -translate-y-2/4;
      content: '';
      top: 50%;
      left: 0;
      width: 23px;
      height: 23px;
      border-radius: 50%;
    }
    &:checked + span::before {
      @apply shadow-formRadioSelected border-red-700;
    }
  }

  .mbx-state--hover &,
  &:hover,
  .mbx-state--focus &,
  &:focus {
    input[type='radio'] + span::before {
      @apply bg-red-100 border-red-700;
    }
  }
}
