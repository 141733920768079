.mbx-list_item {
  @apply flex items-center justify-between;
  height: 81px;
  border-top: 1px solid;
  @apply border-gray-300;

  &__header {
    @apply flex items-center;
  }

  &__title {
    @apply font-bold;
    display: -webkit-box;
    flex-shrink: 0;
    width: 134px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
    line-height: 18px;
  }

  &__text {
    @apply ml-16;
    display: -webkit-box;
    flex: 1;
    align-self: flex-start;
    max-height: 60px;
    padding: 17.5px 0;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 10px;
    line-height: 15px;
  }

  &__buttons {
    @apply ml-16;
  }

  &__button {
    .button {
      border-width: 1px;
    }

    &:not(:first-child) {
      @apply ml-8;
    }
  }

  &__toggle {
    @apply ml-16;

    .mbx-formToggle {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__icon {
    @apply mr-6;
    display: block;
    width: 24px;
    height: 24px;
  }
}
