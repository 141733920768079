.mbx-status_clients {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  @apply px-24;
  @apply py-16;

  &__title {
    @apply text-12_20;
    @apply font-bold;
    display: flex;
    @apply items-center;
    position: relative;
    i {
      font-size: 18px;
      cursor: pointer;

      &:hover + .mbx-admin_tooltip {
        display: block;
      }
    }
  }
  &__action {
    border-radius: 4px;
    border: 1px solid #bdbdbd;
    display: flex;
    @apply my-4;
    @apply items-center;
    @apply justify-center;
    width: 100%;
    height: 36px;
    @apply text-12_20;
    i {
      font-size: 18px;
    }

    //ステータス
    &.status-0 {
      @apply bg-gray-200;
      @apply text-gray-600;
      border: none;
    }
  }
  &__action-done {
    border-radius: 4px;
    display: flex;
    @apply my-4;
    width: 100%;
    height: 36px;
    @apply text-12_20;
    line-height: 1.2;
    & > span {
      display: flex;
      height: 36px;
      @apply items-center;
      i {
        font-size: 18px;
      }
    }
    &-status {
      text-align: left;
    }

    //ステータス
    &.status-1 {
      & > span:first-child {
        @apply bg-success-200;
        @apply text-success-700;
      }
      & > span:last-child {
        @apply bg-success-300;
        @apply text-blue-900;
      }
    }

    &.status-2 {
      & > span:first-child {
        @apply bg-attention-200;
        @apply text-attention-700;
      }
      & > span:last-child {
        @apply bg-attention-300;
      }
    }

    &.status-3,
    &.status-6,
    &.status-7 {
      & > span:first-child {
        @apply bg-red-200;
        @apply text-red-700;
      }
      & > span:last-child {
        @apply bg-red-300;
      }
    }

    &.status-4,
    &.status-5,
    &.status-10 {
      & > span:first-child {
        @apply bg-gray-200;
        @apply text-gray-600;
      }
      & > span:last-child {
        @apply bg-gray-300;
      }
    }

    &.status-8,
    &.status-9,
    &.status-11 {
      & > span:first-child {
        @apply bg-blue-300;
        @apply text-blue-700;
      }
      & > span:last-child {
        @apply bg-blue-400;
        @apply text-blue-900;
      }
    }

    //内部
    &-status {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      @apply px-16;
      width: calc(170% / 232 * 100);
      letter-spacing: -0.01em;
    }
    &-rate {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      @apply px-8;
      @apply justify-between;
      width: calc(62% / 232 * 100);
      span {
        display: inline-block;
        width: 50px;
      }
    }
  }
  &__detail {
    @apply text-12_20;
    p {
      @apply text-blue-700;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
