@import '../../mixins/mq';

.block-section {
  .content {
    @include mq(md) {
      width: 100% !important;
    }
    @include mq(sm) {
      width: 100% !important;
    }
    @include mq(lg) {
      width: 784px !important;
    }
  }
  .child {
    border-radius: 4px;
  }
}
.block-modal {
  width: 784px;
  min-height: 768px;
  padding-top: 86px;
  @include mq(md) {
    width: 100%;
  }
  @include mq(sm) {
    width: 100vw;
    min-height: 101vh;
    padding-top: 48px;
  }
  .search-btn {
    width: 88px;
    margin-left: 8px;
    .button {
      @include mq(sm) {
        height: 100%;
      }
    }
  }
  .btn-308 {
    width: 308px;
    .button {
      @include mq(sm) {
        height: 100%;
      }
    }
  }
}
