.mbx-image_general {
  width: 100%;
  height: 100%;
  &_outer {
    height: 351px;
  }
  &_inner {
    width: 100%;
    height: 100%;
  }
  &_image {
    width: 100%;
    height: 100%;
    display: block;
    background-size: contain;
    background-position: center;
  }
  &_input-file {
    width: 1px;
    height: 1px;
  }
  &_input {
    width: 100%;
    height: 100%;
    // &:hover, &:active {
    //   background: #F4F9FE;
    //   color: #A3BCD6
    // }
    & > div {
      color: #C4C4C4;
      font-size: 32px;;
    }
    & > span {
      color: #757575;
      font-size: 10px;
      display: block;
    }
  }
}
