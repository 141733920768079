.mbx-modal-editable {
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  overflow: scroll;
  &__content {
    width: 100vw;
    height: 100%;
  }
}
