@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-body overflow-y-scroll;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-all;
}

code {
  @apply font-code;
}

img {
  width: 100%;
}
