.mbx-save_search_conditions_modal {
  @apply flex   items-center justify-center;
  width: 624px;
  height: 405px;
  border-radius: 4px;

  &__inner {
    @apply flex flex-col items-center justify-center;
    width: 464px;
  }

  &__title {
    @apply font-bold text-16_24;
  }

  &__input {
    @apply mt-24;
    width: 100%;
  }

  &__text {
    @apply mt-8 bg-gray-100 text-left p-16;
    width: 100%;
    border-radius: 4px;
    font-size: 10px;
    line-height: 15px;
  }

  &__ui {
    @apply mt-24;
  }

  &__button {
    width: 146px;

    &:not(:first-child) {
      @apply ml-16;
    }
  }
}
