.top,
.interview,
.event {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    &-inner {
      nav {
        flex-direction: column;

        ul {
          li {
            display: flex;
            align-items: center;

            &:not(:first-child) {
              margin-left: 26px;
            }
          }
        }
      }
    }

    &-btn {
      width: 196px;
    }
  }

  .interview {
    width: 980px;
    margin: 15px auto 0;
    padding-top: 98px;
    padding-bottom: 160px;

    &-title {
      font-size: 26px;
      font-weight: bold;
      line-height: 60px;
      text-align: center;
    }

    &-subtitle {
      @apply font-fira;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.1em;
      text-align: center;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 280px);
      justify-content: space-between;
      margin-top: 45px;

      a {
        transition-duration: .15s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .profile {
      margin-top: 14px;
      padding-bottom: 8px;
      border-bottom: 1px solid #cccccc;
    }

    .num,
    .name {
      font-size: 15px;
    }

    .job {
      margin-left: 22px;
      font-size: 12px;
    }

    .name {
      margin-left: 20px;
    }

    .question {
      margin-top: 16px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.2px;
      line-height: 30px;
    }

    .answer {
      display: -webkit-box;
      max-height: 44px;
      margin-top: 8px;
      overflow: hidden;
      font-size: 13px;
      letter-spacing: 0.5px;
      line-height: 22px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .top-footer {
    padding-top: 94px;
    background-color: #f7f7f7;

    .text {
      display: block;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }

    img {
      width: 180px;
      margin: 18px auto 0;
    }

    .contact {
      display: flex;
      justify-content: center;
      margin-top: 42px;

      .link {
        margin: 0 15px;

        span {
          color: #333333;
          font-size: 14px;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }

    &_bottom {
      margin-top: 90px;
      background-color: #333333;
      color: #ffffff;

      .bottom-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 980px;
        height: 97px;
        margin: 0 auto;

        .copyright {
          font-size: 13px;
          letter-spacing: 0.0075em;
        }

        .links {
          display: flex;

          .link {
            span {
              @apply text-white;
              font-size: 14px;
              font-weight: unset;
              letter-spacing: 0.0075em;
            }

            &:not(:first-child) {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}

.interview-auth,
.event-auth {
  .header {
    position: relative !important;

    &-inner {
      nav {
        flex-direction: row !important;

        ul {
          li {
            &:not(:first-child) {
              margin-left: 64px !important;
            }
          }
        }
      }
    }
  }
}

.top {
  overflow: hidden;
  color: #333333;
  line-height: 1;

  .main-bnr {
    margin-top: 80px;
    font-weight: bold;
    position: relative;

    .main-slide {
      position: relative;
      width: 100%;
      height: 650px;
      overflow: hidden;

      h3 {
        position: absolute;
        z-index: 1;
        top: 82px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 32px;
        white-space: nowrap;

        br {
          display: none;
        }
      }

      img {
        position: absolute;
        max-width: unset;
        width: unset;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .slick-dots {
      bottom: -48px;

      .slick-active {
        button {
          &::before {
            color: #ff5b38;
          }
        }
      }
    }

    .mynaviworks-logo {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: end;
      width: 100%;
      max-width: 2462px;

      img {
        width: auto;
      }
    }
  }

  .top-list {
    display: grid;
    grid-template-columns: repeat(3, 280px);
    justify-content: space-between;
    width: 980px;
    margin: 45px auto 0;
    padding-top: 98px;
    padding-bottom: 160px;

    .list {
      transition: filter 0.2s;
    }

    .list:hover {
      filter: opacity(70%);
    }

    .list-title {
      margin-top: 28px;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.2px;
    }

    .list-text {
      margin-top: 20px;
      font-size: 14px;
      letter-spacing: -0.2px;
      line-height: 24px;
    }
  }

  .event {
    width: 980px;
    margin: 42px auto 0;
    padding-top: 98px;

    .event-bg {
      padding: 33px 60px 60px;
      width: 100%;
      height: 255px;

      .event-title {
        font-size: 26px;
        font-weight: 700;
        line-height: 60px;
      }

      .event-subtitle {
        font-family: "Fira Sans", sans-serif;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: .1em;
      }

      .event-text {
        margin-top: 20px;
        letter-spacing: .05em;
      }

      .event-link {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -.2px;
        line-height: 30px;
        text-align: right;
      }

      .mbx-icon {
        position: relative;
        top: 2px;
        display: inline-block;
        padding-right: 2px;

        &::before {
          vertical-align: baseline !important;
        }
      }
    }
  }

  .introduction {
    padding-bottom: 168px;
    background-size: cover;

    &-top {
      display: flex;
      position: relative;
      justify-content: space-between;
      width: 980px;
      margin: 0 auto;
      padding: 0 30px;
      padding-left: 37px;

      .wrap {
        padding-top: 150px;
        padding-bottom: 66px;
      }

      .introduction-images {
        position: absolute;
        right: 30px;
      }
    }

    &-bottom {
      display: flex;
      position: relative;
      left: 0;
      justify-content: flex-end;
      width: 980px;
      margin: 160px auto 0;

      .wrap {
        padding-top: 28px;
        padding-bottom: 54px;
      }

      .introduction-images {
        position: absolute;
        left: 0px;
      }
    }

    &-title {
      font-size: 25px;
      font-weight: bold;
      letter-spacing: 0.05em;
      line-height: 58px;
    }

    &-text {
      margin-top: 52px;
      letter-spacing: 0.05em;
      line-height: 46px;
    }

    &-btn {
      width: 123px;
      margin-top: 68px;
      border: 1px solid #a1a1a1;
      border-radius: 4px;
      background-color: #f3f5ee;
      color: #636665;
      font-size: 14px;
      font-weight: bold;
      line-height: 44px;
      text-align: center;
      cursor: pointer;

      &:hover {
        @apply bg-white;
      }
    }

    .lead-img01 {
      width: 432px;
      transform: translateY(-30px);
    }

    .lead-img02 {
      width: 432px;
      height: 264px;
      object-fit: 'none';
      transform: translate(-109px, -148px);
    }

    .lead-img03 {
      width: 590px;
      transform: translateX(-57px);
    }

    .lead-img04 {
      width: 365px;
      object-fit: 'none';
      transform: translate(200px, -54px);
    }

    //仮入れリンク
    .logo-link {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-top: 89px;

      >p {
        &:nth-child(1) {
          font-size: 16px;
          line-height: 24px;
          padding-right: 42px;
          border-right: 1px solid #a1a1a1;
        }

        &:nth-child(2) {
          font-size: 15px;
          padding-left: 36px;
          color: #0857a8;

          i {
            font-size: 24px;
            margin-left: 10px;
          }

          a {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .logo-list {
      @apply bg-white;
      display: flex;
      align-items: center;
      width: 980px;
      height: 100px;
      margin: 150px auto 0;
      border-radius: 4px;

      .text {
        width: 170px;
        border-right: 1px solid #a1a1a1;
        font-weight: bold;
        line-height: 27px;
        text-align: center;
      }

      .list {
        width: 100%;

        ul {
          display: flex;
          justify-content: space-evenly;
          /*margin-left: 50px;*/
        }

        li {
          display: flex;
          align-items: center;
          width: 146px;

          &:not(:first-child) {
            margin-left: 3px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .start {
    padding: 94px 0;
    text-align: center;

    p {
      font-size: 15px;
      letter-spacing: 0.6px;
    }

    .base-button {
      width: 270px;
      height: 56px;
      margin-top: 40px;

      .label {
        font-size: 17px;
      }
    }
  }

  .recruit-voice {
    position: relative;
    background-color: #e2ebea;
    background-repeat: no-repeat;
    background-size: 100% auto;

    &_inner {
      display: flex;
      position: relative;
      width: 980px;
      margin: 0 auto;
      padding-top: 197px;
      padding-bottom: 150px;

      .wrap {
        padding-left: 108px;
      }
    }

    &_title {
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 1px;
    }

    &_subtitle {
      @apply font-fira;
      margin-top: 21px;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.1em;
    }

    &_description {
      margin-top: 60px;
      letter-spacing: 0.03em;
      line-height: 46px;
    }

    .slide-control {
      position: absolute;
      right: 45px;
      bottom: 240px;

      .disabled {
        opacity: 0.3;
      }

      img {
        width: 12px;
        height: 24px;
        transform: rotate(90deg);

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }

    &_slider {
      width: 386px;
      height: 100%;
      margin-left: 92px;

      .slick-arrow {
        display: none !important;
      }

      .slick-slide {
        height: 177px;
        transition-duration: .15s;

        &:hover {
          opacity: 0.7;
        }
      }

      .slide-item {
        .item {
          display: flex;
        }

        .slide-text {
          margin-left: 28px;

          .name {
            font-weight: bold;
            letter-spacing: 0.1em;
            line-height: 24px;
          }

          .text {
            font-size: 15px;
            letter-spacing: 0.005em;
            line-height: 27px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }

        img {
          display: inline-block;
          width: 128px !important;
          height: 128px;
        }
      }
    }
  }

  .everyone-portfolio {
    padding-top: 175px;
    padding-bottom: 180px;

    &_title {
      font-size: 28px;
      font-weight: bold;
      text-align: center;
    }

    &_subtitle {
      @apply font-fira;
      margin-top: 24px;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.095em;
      text-align: center;
    }

    .portfolio-slider {
      &_control {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding: 0 120px;

        img {
          width: 12px;
          height: 24px;
        }
      }
    }

    .slide-items {
      margin-top: 50px;

      .slick-list {
        overflow: visible !important;
      }

      .slide-item {
        position: relative;
        z-index: 2;
        transform-origin: 50% 50%;
        transition: transform 0.3s cubic-bezier(0.5, 1, 0.89, 1);

        .bnr-area {
          margin: 0 23px;
          padding: 16px;
          box-shadow: 0px 0px 8px 0px rgba(111, 119, 116, 0.2);

          img {
            width: 238px;
            border: 1px solid #f2f2f2;
          }
        }

        .title {
          margin-left: 23px;
          padding-top: 24px;
          font-size: 17px;
          letter-spacing: 0.01em;
        }

        .user-info {
          display: flex;
          align-items: center;
          margin-top: 30px;
          margin-left: 23px;

          img {
            width: 68px;
            height: 68px;
            border-radius: 50%;
          }

          .name {
            margin-left: 10px;
            font-size: 14px;
            font-weight: bold;
          }

          .job {
            margin-top: 15px;
            margin-left: 10px;
            font-size: 12px;
          }
        }

        &:hover {
          transform: scale(1.2);
          transform-origin: 50% 50%;
          transition: transform 0.3s cubic-bezier(0.5, 1, 0.89, 1);
          color: #808080;
        }
      }
    }
  }

  .questions {
    padding-top: 177px;
    padding-bottom: 176px;

    &-inner {
      width: 980px;
      margin: 0 auto;
    }

    &-title {
      font-size: 28px;
      font-weight: bold;
      text-align: center;
    }

    &-subtitle {
      @apply font-fira;
      margin-top: 19px;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
    }

    &-contents {
      display: flex;
      justify-content: space-between;
      margin-top: 70px;

      .content {
        width: 260px;
        border-bottom: 1px solid #a1a1a1;

        .q-num {
          padding-bottom: 10px;
          border-bottom: 1px solid #a1a1a1;
          font-size: 18px;
          text-align: center;
        }

        .question {
          margin-top: 22px;
          font-weight: bold;
          letter-spacing: 0.02em;
          line-height: 28px;
        }

        .answer {
          margin-top: 16px;
          padding-bottom: 24px;
          font-size: 15px;
          letter-spacing: 0.03em;
          line-height: 30px;
        }
      }
    }

    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 78px;

      i {
        @apply text-button-iconButton-red;
        font-size: 23px;
      }

      p {
        margin-left: 16px;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.01em;
      }
    }
  }
}
