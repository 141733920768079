@import '../../mixins/mq';

.mbx-news_module {
  width: 100%;
  max-width: 345px;
  @include mq('sm') {
    max-width: 100%;
    padding: 0 8px;
  }
  a {
    display: block;
    position: relative;
    overflow: hidden;
    @apply bg-white;
    transition: box-shadow 0.5s ease;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.3);
      .mbx-icon-External_link {
        opacity: 1;
      }
    }
  }
  .mbx-icon-External_link {
    position: absolute;
    top: 8px;
    right: 8px;
    transition: opacity 0.5s ease;
    opacity: 0;
    color: #fff;
    font-size: 40px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  }
  &-title {
    @apply text-14_21 px-24 py-16 sm:text-12_18 sm:px-20 sm:py-14;
    text-align: left;
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      min-height: 42px;
      overflow: hidden;
      @include mq('sm') {
        min-height: 37px;
      }
    }
  }
}
