.mbx-user-profile {
  display: flex;
  align-items: center;
  & > img {
    width: 64px;
    height: 64px;
    margin-right: 12px;
    border-radius: 50%;
  }
  & > section {
    h2 {
      @apply text-16_24 font-bold mb-8;
    }
  }
}
