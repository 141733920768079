@import '../../../mixins/mq';

.mbx-add_link_register {
  width: 496px;
  margin: auto;
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: 14px;
    margin: 0 0 1.5em;
    @include mq('sm') {
      margin: 0 0 8px;
    }
  }

  small {
    font-size: 10px;
    display: block;
    margin: 6px 0 12px;
    @include mq('sm') {
      margin: 8px 0;
    }
  }
  &_input-file {
    width: 1px;
    height: 1px;
    overflow: hidden;
    display: block;
  }
}

