.mbx-imagepage-button {
  width: 100%;
  height: 56px;
  display: block;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  position: relative;
  &:focus {
    outline: 0;
  }

  background-color: white;
  border-color: #BDBDBD;
  font-weight: 700;
  font-size: 11px;
  i {
    color: #c4c4c4;
    position: absolute;
    left: 16px;
    top: calc(56px / 2);
    transform: translateY(-50%);
    font-size: 24px;
  }

  &:hover, &:active {
    @apply text-blueGray-600;
    @apply bg-blue-100;
    border-color: #A3BCD6;
    i {
      @apply text-blueGray-400;
    }
  }
}

