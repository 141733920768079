@import '~@/styles/mixins/mq';

.mbx-directbox {
  @apply bg-bg-direct text-center border-t border-b border-blueGray-200;
  &-wrap {
    @apply items-center justify-between w-full flex flex-row-reverse;
    background: url('~@/assets/images/top/direct_pc.jpg') left top no-repeat;
    background-size: 450px auto;
    max-width: 960px;
    height: calc(778px / 2);
    margin: 0 auto;
    @include mq('sm') {
      background: url('~@/assets/images/top/direct_sp.jpg') left bottom no-repeat;
      background-size: 375px auto;
      justify-content: center;
      height: auto;
      padding-bottom: 250px;
    }
  }
  &-title {
    @apply text-22_27 font-bold mb-30 sm:text-18_33 sm:pt-50;
  }
  &-description {
    @apply text-14_28 mb-30 sm:text-body_1;
  }
}
