.header-title {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  @apply bg-gray-200 border-b border-gray-300 sm:px-16 md:px-16;
  &_inner {
    @apply h-56 sm:h-48;
    display: flex;
    align-items: center;
    margin: auto;
    .title {
      @apply text-text font-bold;
      flex: 0 0 auto;
      width: auto;
    }

    .children-area {
      width: 100%;
      .edit-button {
        width: 140px;
        @media (max-width: 767px) {
          width: auto;
        }
      }
    }
  }
}
