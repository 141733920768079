@import '../../mixins/mq';
.step1 {
  .send-btn {
    width: 308px;
    @include mq(sm) {
      width: 100%;
    }
    .button {
      @include mq(sm) {
        height: 48px;
      }
    }
  }

  .base-button {
    .button {
      span {
        @include mq(sm) {
          font-size: 14px;
        }
      }
    }
  }
}
