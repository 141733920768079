.mbx-textbox-color {
  @apply bg-blue-100;
  @apply border-blue-200;
  border-radius: 4px;
  border-width: 1px;
  --tw-border-opacity: 1;
}
.mbx-textbox {
  display: block;
  width: 100%;
  font-size: 14px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 16px;
}
