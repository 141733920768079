@import '../../mixins/mq';
.mbx-image_responsive {
  width: 100%;
  height: 100%;
  &_inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &_pc {
    background: url(/images/mockup-mobile-device-pc_preview.png) no-repeat center center;
    background-size: cover;
    width: calc(100% * 392 / 576);
    height: calc(100% * 253 / 384);
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 14px 53px 35px 6px;
    font-size: 32px;
    @include mq('sm') {
      padding: 2% 8.7% 6% 0;
    }
  }
  &_tablet {
    background: url(/images/mockup-mobile-device-tablet_preview.png) no-repeat center center;
    background-size: cover;
    width: calc(100% * 175 / 576);
    height: calc(100% * 244 / 384);
    top: 51.5%;
    left: 23.5%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 22px 14px 26px 14px;
    font-size: 25px;
    @include mq('sm') {
      padding: 4% 2.4% 4.8% 2.4%;
    }
  }
  &_sp {
    background: url(/images/mockup-mobile-device-sp_preview.png) no-repeat center center;
    background-size: cover;
    width: calc(100% * 75 / 576);
    height: calc(100% * 144 / 384);
    top: 65%;
    left: 34%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 18px 8px 22px;
    font-size: 16px;
    @include mq('sm') {
      padding: 3.3% 1.4% 4% 1.4%;
    }
  }
}
