.mbx-top-preview {
  //ヘッダー部分
  &-header {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
  &-profile {
    margin-top: -20px;
    display: flex;
    @media screen and (max-width: 767px) {
      margin-top: -56px;
      flex-direction: column;
      @apply sm:mb-8;
    }

    align-items: center;
    &__thumb {
      width: 128px;
      margin-right: 40px;
      @media screen and (max-width: 767px) {
        width: 80px;
        margin-right: 0;
      }
    }
    &__inner {
      padding-top: 20px;
    }
  }

  //各ポートフォリオサムネイル
  &-works {
    display: flex;
    @apply lg:justify-start lg:-ml-48 md:-ml-48;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    flex-wrap: wrap;
    @apply mt-48;
    li {
      @apply mb-48 md:ml-48 lg:ml-48;
      @media screen and (min-width: 768px) {
        flex: 0 0 288px;
      }
      @media screen and (max-width: 767px) {
        flex: 0 0 100%;
      }
    }
  }

  &-wrapper {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .folio {
    @media screen {
      display: none;
    }
  }
}
.sp_only {
  display: none;
}
@media screen and (max-width: 826px) {
  .mbx-top-preview-wrapper{
    justify-content: flex-end !important;
  }
  .sp_only {
    display: block;
  }
  .sp_none {
    display: none !important;
  }
  .sp-edit_btn {
    .base-button {
      width: 100%;
    }
  }
}
