.mbx-blank {
    @apply bg-gray-50;
    border: 1px solid #eeeeee;
    height: 351px;
    display: flex;
    &_input-file{
        width: 1px;
        height: 1px;
        position: absolute;
    }
}

