.mbx-formContainer {
  @apply block w-full text-14_21 sm:text-16_24 border border-border-form rounded-formContainer outline-none transition-all;

  .double-l & {
    border-radius: 0px;
    @apply rounded-l-formContainer border-r-0;
  }
  .double-r & {
    border-radius: 0px;
    @apply rounded-r-formContainer;
  }

  &::placeholder {
    @apply text-text-placeholder;
  }

  .mbx-state--hover &,
  &:hover {
    @apply border-border-form-hover;
  }

  .mbx-state--focus &,
  &:focus {
    @apply shadow-formContainerFocus border-border-form-hover bg-white;
  }
  // Textarea
  &--textarea {
    @apply p-16 resize-none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &--readonly {
      @apply border-0 shadow-none  pl-0 pr-0 py-16 #{!important};
      min-height: 21px;
    }
  }

  // Textfield
  &--textfield-wrapper {
    @apply relative;
  }
  &--textfield {
    @apply pl-10 pr-10 h-48;

    &.focus {
      & + .mbx-formContainer--textfield-clear {
        @apply block;
      }
    }
    // tailwindは本番用にビルドする場合はpurgeオプションを使用して未使用のスタイルを削除し、最終的なビルドサイズを最適化しているが
    // 以下のようなjs内の変数でのみ作成されるようなセレクタは検知できないため本番用から削除されてしまう。
    // tsxの例
    // <test_component size={button} />
    //
    // <p className={text-${button}}>test</p>
    //
    // text-button は変数を介してしか使われない場合本番時には生成されない
    //
    // そのため本来なら不要なカラー設定 text-button-iconButton-clear をここでして生成されるようにしてある。
    // 独自セレクタをはさめば抜本的に解決をすることが可能だがひとまずこの方法で回避
    &-clear {
      @apply absolute hidden text-button-iconButton-clear;
      top: 12px;
      right: 9px;
    }

    &--readonly {
      @apply border-0 shadow-none pl-0 pr-0 py-14 #{!important};
      min-height: 21px;
    }
    &--low {
      @apply h-36;
      & + .mbx-formContainer--textfield-clear {
        top: 6px;
      }
    }
  }

  &--with-icon {
    @apply pl-35;
  }

  // Dropdown
  &--dropdown-wrapper {
    @apply relative;
    z-index: 1;
    transition: z-index 0.01s ease 0.15s;
    min-height: 48px;
    &.admin {
      // 管理画面の場合は高さが短くなる
      min-height: 36px;
    }
    &.is-show {
      z-index: 2;
      transition: z-index 0.01s ease;
      .mbx-formContainer--dropdown-multiple-wrapper {
        select {
          pointer-events: none;
        }
      }
      .mbx-formContainer--dropdown-multiple-container {
        pointer-events: none;
      }
    }
    &.disabled {
      .mbx-formContainer--dropdown-multiple-container {
        @apply cursor-default border-border-form;
      }
      .mbx-formContainer--dropdown-multiple-placeholder {
        @apply text-gray-500;
      }
    }
    ::-ms-expand {
      display: none;
    }
  }
  &--dropdown {
    @apply pl-10 pr-42 cursor-pointer appearance-none h-48;
    &.admin {
      @apply h-36;
    }
    .is-show & {
      & + .mbx-formContainer--dropdown-arrow {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &-arrow {
      @apply absolute;
      top: 50%;
      right: 9px;
      transform: translateY(-50%) rotate(0);
      transition: transform 0.3s ease;
      pointer-events: none;
      z-index: 2;
    }

    &--readonly {
      @apply border-0 shadow-none pl-0 pr-0 py-14 #{!important};
    }
    &.hidden {
      display: none;
    }

    &-multiple {
      &-wrapper {
        .mbx-formContainer--dropdown {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          height: auto;
          overflow: hidden;
          -webkit-appearance: none;
          appearance: none;
          @apply bg-white sm:text-16_24;
        }
        option {
          // display: none;
          opacity: 0;
          pointer-events: none;
        }
      }
      &-container {
        @apply block w-full text-14_21 border border-border-form rounded-formContainer outline-none transition-all cursor-pointer absolute bg-white;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: auto;
        .mbx-state--hover &,
        .double-l & {
          border-radius: 0px;
          @apply rounded-l-formContainer border-r-0;
        }
        .double-r & {
          border-radius: 0px;
          @apply rounded-r-formContainer;
        }
        &:hover {
          @apply border-border-form-hover;
        }
      }
      &-list {
        position: relative;
        z-index: 1;
        padding: 4px 42px 4px 2px;
        pointer-events: none;
        &.admin {
          padding: 0px 42px 0px 2px;
        }
      }
      &-placeholder {
        @apply text-14_21 sm:text-16_24 flex font-normal absolute items-center overflow-hidden pointer-events-none;
        top: 0;
        right: 42px;
        bottom: 0;
        left: 0;
        padding: 10px 20px 10px 10px;
        p {
          white-space: nowrap;
        }
      }
    }
  }

  //Datepicker
  &--datepicker-wrapper {
    .react-datepicker-popper {
      z-index: 3 !important;
    }
  }

  &:disabled {
    @apply border border-border-form-disabled bg-bg-form-disabled text-text-disabled shadow-none #{!important};
    cursor: default;
    & + .mbx-formContainer--dropdown-arrow {
      opacity: 0.3;
    }
  }
  &--error {
    input,
    textarea {
      @apply border-border-form-error bg-error-50 shadow-formContainerError #{!important};

      & + .mbx-formContainer--textfield-clear {
        @apply bg-error-50;
      }
      .mbx-state--focus &,
      &:focus {
        @apply bg-white #{!important};
        & + .mbx-formContainer--textfield-clear {
          @apply bg-white;
        }
      }
    }
    select {
      @apply border-border-form-error bg-error-50 shadow-formContainerError #{!important};

      & + .mbx-formContainer--textfield-clear {
        @apply bg-error-50;
      }
    }
    .mbx-formContainer--dropdown-multiple-container {
      @apply border-border-form-error bg-error-50 shadow-formContainerError #{!important};
      & + .mbx-formContainer--textfield-clear {
        @apply bg-error-50;
      }
    }
  }
}
