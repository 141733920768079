.mbx-layout_select {
  width: 141px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  button {
    outline: 0;
    width: 100%;
    height: 100%;
    &>img:last-child{
      display: none;
    }
    &:hover, &active {
      &>img:first-child{
        display: none;
      }
      &>img:last-child{
        display: inline-block;
      }
    }
  }
  &-selected {
    button {
      &>img:first-child{
        display: none;
      }
      &>img:last-child{
        display: inline-block;
      }
    }
  }
}