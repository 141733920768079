@media screen {
  .page_columns {
    @apply flex;
    @apply sm:flex-col-reverse;
    @apply md:flex-col-reverse;
    @apply lg:flex-row;
    @apply lg:justify-between;

    .main {
      @apply lg:w-624;
    }
    .menu {
      @apply lg:w-288;
    }
  }
}
@media print {
  .page_columns {
    @apply flex;
    @apply flex-row;
    @apply justify-between;

    .main {
      @apply w-624;
    }
    .menu {
      @apply w-288;
    }
  }
}
