.header-image {
  width: 100%;

  &_inner {
    @apply bg-gray-300;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    .header-icons {
      display: flex;
      flex-direction: column;
      .camera-icon, .close-icon {
        width: 120px;
        height: 36px;
        border-radius: 20px;
        background: #616161;
        position: relative;
        cursor: pointer;
        input {
          display: none;
        }
        i {
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 20%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          font-size: 23px;
        }
        p {
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 32%;
          transform: translateY(-50%);
          color: #ffffff;
          font-size: 12px;
        }
      }
      .close-icon {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header-image {
    &_inner {
      .header-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        .camera-icon {
          margin-right: 10px;
        }
        .close-icon {
          margin-top: 0px;
          margin-left: 10px;
        }
      }
    }
  }
}
