@import '../../../mixins/mq';

.mbx-add_image {
  width: 496px;
  display: flex;
  &_inner {
    margin: auto;
    h1 {
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      margin: 0 0 1.5em;
    }

    small {
      font-size: 10px;
      display: block;
      margin: 6px 0 12px;
    }
  }
  &_buttons {
    display: flex;
    justify-content: space-between;
  }
  &_button {
    &:hover, &:active {
      border: 1px solid #A3BCD6;
      background: #F4F9FE;
      &.icon {
        background: #F4F9FE;
        color: #A3BCD6
      }
    }
    &.icon {
      background: #EEEEEE;
      color: #BDBDBD;
      font-size: 21px;
    }
    width: 156px;
    height: 104px;
    @include mq('sm') {
      width: 88px;
      height: 58px;
    }
    border: 1px solid #BDBDBD;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


