.mbx-career-history-project {
  tbody {
    border-bottom: 1px dotted #BDBDBD;
    td {
      border-top: 1px dotted #BDBDBD;
      word-break: break-word;;
      &:nth-child(1){
        width: 75px;
      }
      &:nth-child(3){
        width: 140px;
      }
      &:nth-child(4){
        width: 140px;
      }
    }
  }
  td {
    padding: 10px;
  }
  tr {
    td {
      border-right: 1px dotted #BDBDBD;
      &:last-child {
        border-right: 0;
      }
      vertical-align: top;
    }
  }
}