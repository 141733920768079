@import '../../mixins/mq';
.password-change {
  .btn {
    width: 100%;
    .button {
      height: 100%;
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @include mq('sm') {
      position: static;
    }
  }
}
