.mbx-typography {
  &--heading_1 {
    @apply text-heading_1;
    @apply font-bold;
  }
  &--heading_2 {
    @apply text-heading_2;
    @apply font-bold;
  }
  &--heading_3 {
    @apply text-heading_3;
    @apply font-bold;
  }
  &--heading_4 {
    @apply text-heading_4;
    @apply font-bold;
  }
  &--subhead_1 {
    @apply text-subhead_1;
    @apply font-bold;
  }
  &--subhead_2 {
    @apply text-subhead_2;
    @apply font-bold;
  }
  &--body_1 {
    @apply text-body_1;
    @apply font-medium;
  }
  &--body_2 {
    @apply text-body_2;
    @apply font-medium;
  }
  &--caption_1 {
    @apply text-caption_1;
    @apply font-medium;
  }
  &--caption_2 {
    @apply text-caption_2;
    @apply font-medium;
  }
}
