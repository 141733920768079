.mbx-basic_info {
    .direct-url{
        position: relative;
        .tool-tip {
            position: absolute;
            z-index: 20;
            bottom: 0;
            left: 50%;
            width: max-content;
            padding: 16px;
            transform: translateX(-50%) translateY(100%);
            border-radius: 4px;
            @apply bg-gray-800;
            @apply text-white;
        }
    }
}

