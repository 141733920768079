.portfolio-header_edit {
  color: #000000;
  .inner {
    padding-top: 24px;
    .edit-area {
      display: flex;
      justify-content: space-between;
    }
    .user-img {
      position: relative;
      width: 128px;
      height: 128px;
      margin-bottom: 25px;
      input {
        display: none;
      }
      .thumbnail {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
      .camera-icon {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #616161;
        cursor: pointer;
        i {
          transform: translateX(1px);
          color: #ffffff;
          font-size: 23px;
        }
      }
    }
    .input-box {
      align-items: center;
      height: 100%;
    }
    .input-layout {
      width: 388px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .portfolio-header_edit {
    .inner {
      padding-top: 30px;
      .edit-area {
        margin-bottom: 14px;
      }
      .user-img {
        margin-top: 25px;
      }
      .input-box {
        display: block;
      }
      .input-layout {
        width: 456px;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .portfolio-header_edit {
    .inner {
      .input-box {
        margin-top: 30px;
      }
    }
  }
}
