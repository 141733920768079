.mbx-preview {
  margin-top: 48px;
  margin-bottom: 80px;
  line-break: strict;
}

.mbx-preview-page {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 840px;
  min-height: 1188px;
  transform-origin: top left;
  &_auto-height {
    height: auto;
    min-height: 1188px;
  }
}

.mbx-utils-stack-v--preview {
  & > * + * {
    @apply mt-48;
  }
}

.mbx-preview-fix-btn-area {
  position: sticky;
  right: 0;
  bottom: 0;
  text-align: right;
  z-index: 16;
  @apply pr-24 pl-24 pb-40;
}

@media screen and (max-width: 767px) {
  .mbx-preview {
    @apply mt-24;
    @apply mb-24;
  }
  .mbx-utils-stack-v--preview {
    & > * + * {
      @apply mt-16;
    }
  }
}
