@import './mixins/mq';
.mbx-admin-header {
  position: relative;
  height: 80px;
  @include mq(sm) {
    height: 55px;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
    @include mq(md) {
      width: 100%;
      padding-right: 8px;
      padding-left: 16px;
    }
    @include mq(sm) {
      width: 100%;
      padding-right: 8px;
      padding-left: 16px;
    }
    @include mq(lg) {
      width: 960px;
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  .mbx-admin-menu {
    position: absolute;
    top: 80px;
    right: 0;
    width: 288px;
    height: auto;
    transition: opacity 100ms ease-out;
    border-top: 1px solid #e0e0e0;
    opacity: 0;
    background: white;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
    pointer-events: none;
    @apply z-30;
    @apply lg:rounded-4;

    @include mq(lg) {
      left: calc(50% + (960px / 2) - 288px);
    }

    @include mq(sm) {
      top: 55px;
      left: 0;
      width: 100%;
    }

    &.enter {
      opacity: 1;

      &-done {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }
}
