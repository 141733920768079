.mbx-skill-box {
  display: inline-block;
  .ul {
    display: flex;
    li {
      width: 12px;
      height: 18px;
      &:not(:first-child) {
        margin-left: 2px;
      }
      img {
        filter: grayscale(100%);
        display: block;
      }
      &.active {
        img {
          filter: none;
        }
      }
    }
  }
}
