@import '../../mixins/mq';

.mbx-formUnit-education {
  &-box {
    @apply mt-16 sm:mt-8;
    &:first-child {
      @apply mt-0;
    }
  }
}
