.mbx-add_button {
  width: 100%;
  height: 100%;
  border: 1px dashed #BDBDBD;
  cursor: pointer;

  & > div {
    color: #C4C4C4;
    font-size: 24px;;
  }
  &:hover, &:active {
    background: #F4F9FE;
    color: #A3BCD6
  }
}