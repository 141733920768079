.mbx-assetsbox {
  padding: 36px 48px 48px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  .base-button {
    display: block;
    width: 100%;
    max-width: 192px;
    margin-right: auto;
    margin-left: auto;
  }
}
