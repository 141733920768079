// https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=278%3A106
// 求職者サイトと微妙に表示仕様が違うのでスタイルごとわける
.mbxd-notification {
  @apply absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 960px;
  &-inner {
    @apply rounded-formContainer flex justify-center items-center;
  }
  &-message {
    @apply sm:text-left flex-grow font-bold whitespace-nowrap;
  }
  &-icon {
    flex-basis: 24px;
    flex-shrink: 0;
    text-align: right;
    .mbxd-button-iconButton {
      margin-left: auto;
    }
  }
}

// https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=5332%3A102628
.mbxd-notification--error,
.mbxd-notification--success {
  transform: translate(-50%, -100%);
  z-index: 100;
  .mbxd-notification-inner {
    @apply bg-red-800 text-center text-white mb-32 sm:text-left sm:mx-16 sm:mb-16;
  }

  .mbxd-notification-message {
    @apply py-19 sm:py-12 pr-14 pl-56 text-subhead_2 sm:text-caption_1;
  }
  .mbxd-notification-icon {
    @apply my-14 mr-14;
    flex-basis: 28px;
  }
}

.mbxd-notification--success {
  @apply w-fit left-0;
  transform: translate(0%, -100%);
  .mbxd-notification-inner {
    @apply bg-gray-800;
  }
}
