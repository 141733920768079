.mbx-embedded_link {
  position: relative;
  &_cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.clickable {
      pointer-events: none;
    }
  }

  .thumbnail {
    width: 128px;
    height: 72px;
    position: relative;
    margin: 8px 0;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      color: white;
      background: rgba(black,0.5);
    }
  }
}

