.mbx-button-textLink,
.mbx-button-textLink-noneUnderline {
  @apply text-corporate-blue transition-colors;
  &-body {
    @apply inline-flex items-center;
    span {
      @apply underline;
    }
    i {
      font-size: 1.7em;
    }
  }
  .mbx-state--hover &,
  &:hover {
    @apply text-blue-500;
    .mbx-button-textLink-body {
      span {
        @apply no-underline;
      }
    }
  }
  .mbx-state--focus &,
  &:focus,
  &:active {
    @apply text-blue-800;
    .mbx-button-textLink-body {
      span {
        @apply no-underline;
      }
    }
  }
  button {
    &:disabled {
      @apply text-gray-300 cursor-not-allowed #{!important};
      pointer-events: none;
      .mbx-button-textLink-body {
        span {
          @apply no-underline;
        }
      }
    }
  }
}

.mbx-button-textLink-noneUnderline{
  @apply text-blue-500;
  .mbx-button-textLink-body {
    span {
      @apply no-underline;
    }
  }
}
