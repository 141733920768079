.back-btn {
  a {
    display: inline-flex;
    align-items: center;
    color: #0a58a8;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
  }
}
