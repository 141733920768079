.mbx-tooltip {
  display: flex;
  position: absolute;
  z-index: 10;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
  @apply p-16 rounded-formContainer;
  &--pc {
    @apply smm:hidden;
  }
  &--hover {
    opacity: 1;
  }
  &--touch {
    position: relative;
    transition: none;
    opacity: 1;
    pointer-events: auto;
  }
  &-icon {
    display: block;
    flex-basis: 56px;
    flex-shrink: 0;
    width: 56px;
    margin: 0 auto;
  }
  &-message {
    flex-grow: 1;
    flex-shrink: 1;
    text-align: left;
    @apply text-12_18 pl-8;
    &-from {
      @apply font-bold text-10_10 pb-4;
      @media (max-width: 678px) {
        @apply pb-8;
      }
    }
  }
  &-modal {
    background-color: transparent !important;
  }
  &--matchbou {
    @apply bg-red-700 text-white font-bold;
  }
  &--pdf {
    @apply pointer-events-auto z-400;
  }
  &--mynavi {
    max-width: 424px;
    border: 2px solid #0a58a8;
    @apply p-14 bg-blue-100 text-blue-700;
    @media (max-width: 678px) {
      display: block;
      @apply w-288 p-22;
      .mbx-tooltip-icon {
        @apply pb-8;
      }
      .mbx-tooltip-message {
        padding-left: 0;
      }
    }
  }
}
